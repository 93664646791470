import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel2';

import "./Global";
import { Link, useHistory } from "react-router-dom";



const authAxios =  global.authAxios;

var _isValidFullPostCode = function (postcode) {
    return postcode.match(/^[A-Z](?:\d|\d\d|[A-Z]\d|[A-Z]\d\d|\d[A-Z]|[A-Z]\d[A-Z])\s?\d[A-Z][A-Z]$/i);
};

const Home = () => {

    const [postcode, setPostcode] = useState()
    const [popularCategories, setPopularCategories] = useState()
    const [topRated, setTopRated] = useState()
    const [mostPopular, setMostPopular] = useState()
    const [dropDownList, setDropDownList] = useState()
    const [postcode2, setpostcode2] = useState(localStorage.getItem('post_code2') ? localStorage.getItem('post_code2') : '')

    useEffect(() => {
        dropdownList()
    }, [])

    

    const dropdownList = async () => {
        const res = await authAxios.get(`/foodTypes`);
        setDropDownList(res.data)
      };

    useEffect(() =>{
        setPostcode(localStorage.getItem('post_code'))
        loadPopularCategories()
        topRatedResturants()
        mostPopularResturants()
    }, [postcode])



    let options = {
        items: 5,
        nav: false,
        autoplay: true,
        loop:true,
        
        nav:true,
        margin:20,
        navText : ["<img src='images/left-arrow.png' />","<img src='images/right-arrow.png' />"],
        responsive:{
            0:{
                items:1,

            },
            600:{
                items:3,

            },
            1000:{
                items:5,
                
            }
        }

    };


    let options2 = {
        items: 6,
        nav: false,
        autoplay: true,
        loop:true,
        autoplay:true,
        nav:true,
        margin:20,
        navText : ["<img src='images/o2e-cmp-left.png' />","<img src='images/o2e-cmp-right.png' />"],
        responsive:{
            0:{
                items:1,
            },
            375:{
                items:2
            },
            600:{
                items:4,
            },
            1000:{
                items:6,
            }
        }

    };
   
    let history = useHistory();
    const [takeaway, setTakeaway] = useState({
      post_code: localStorage.getItem('post_code') ? localStorage.getItem('post_code') : '' ,
      food_type: "",
      results: [],
    });

  
    const { post_code } = takeaway;
    const onInputChange = e => {
        setTakeaway({ ...takeaway, [e.target.name]: e.target.value });
        setpostcode2(e.target.value)
    };




    const loadPopularCategories = async () => {
        const res = await authAxios.get(`/popularCategories/${postcode}`);
        setPopularCategories(res.data)
      };


      const topRatedResturants = async () => {
        const res = await authAxios.get(`/topRated/${postcode}`);
        setTopRated(res.data)

      };


      const mostPopularResturants = async () => {
        const res = await authAxios.get(`/mostPopular/${postcode}`);
        setMostPopular(res.data)

      };
    
 
  
    const onSubmit = async e => {

      e.preventDefault();

      localStorage.setItem("post_code", takeaway.post_code)
      var post_code = takeaway.post_code





      var finalPostCode = takeaway.post_code.match(/^(\w{2,4})\s?(\w{3})$/)

      var PostCodeWithSpace = finalPostCode[1] + ' ' + finalPostCode[2]
      setpostcode2(PostCodeWithSpace)
      localStorage.setItem("post_code2", PostCodeWithSpace)

      
      

      if(takeaway.post_code===''){
        alert('Please Enter Post Code');
      }else{

        if (!_isValidFullPostCode(post_code)) {
				
            alert('Enter a valid postcode.');
           
           
        }else{
             
            await authAxios.post('/takeaways', takeaway)
            .then(response => {
                if(response.data.data.length !== 0){
                    history.push("/takeaways/");
                }
                else{
                    history.push("/refer");
                }

              });
        }


      }
     
      
    };

        return (
            <>
            
            <div className="home-page-content body-class">
            <div className="o2e-header-wrapper">
        <div className="container">
            <header className="o2e-header">
                <div className="order2Eat-logo">
                    
                        <img className="o2e-logo" src="./images/logo.png" alt="Order 2 Eat" />
                   
                </div>
            </header>
            <div className="o2e-banner d-md-flex align-items-center justify-content-center">
                <div className="row">
                    <div className="col-md-12 col-lg-5 col-12 mb-md-0 order-lg-2 d-md-flex justify-content-center">
                        <img className="img-fluid desktopview" src="./images/bike.png" alt="" />
                        <img className="img-fluid mobileview" src="./images/order2eat-2.png" alt="" />
                    </div>
                    <div className="col-md-12 col-lg-7 col-12 mb-md-5 mobile-view-column">
                        <form className="search-location d-flex flex-wrap flex-column mb-0" onSubmit={e => onSubmit(e)}>
                            <div className="banner-title text-center text-white mb-md-5 d-none d-md-block">
                                <h2 className="text-capitalize mb-md-3 mb-2">order takeaway &amp; food delivery online</h2>
                                <h5 className="text-uppercase mb-3 mb-md-0">choose from your favouriate  takeaways</h5>
                            </div>
                            <div className="form-row align-items-center flex-fill rounded position-relative b-white p-2 p-md-1 p-relative ">
                                <div className="col-12 col-md menu-seprator d-md-flex d-none p-0">
                                    <select className="select-menu form-control custom-select text-uppercase px-3" id="food_type" name="food_type"  onChange={e => onInputChange(e)}>
                                        
                                    <option>ALL CUISINES</option>
                                    {dropDownList && Array.isArray(dropDownList) ?dropDownList.map((x) => {
                                        return(
                                        <option value={x.id}>{x.title}</option>
                                        )
                                    }): <option>Error</option>}
              
                                    </select>
                                </div>
                                <div className="col-md p-0 mb-md-0 mb-3">
                                    <input type="text" className="form-control text-uppercase px-3" id="post_code" name="post_code" placeholder="ENTER POSTCODE" value={postcode2} onChange={e => onInputChange(e)} />
                                </div>
                                <div className="col-md-auto mobile-view-btn p-0 home-search">
                                    <button  id="btnSearch" className="btn-submit btn text-uppercase px-md-3">
                                        <span className="">Feed me Fast</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                   
                    </div>
                </div>
            </div>
        </div>


        
    </div>

    <section class="section o2e-paymentmethod" >
        <div class="container-fluid" >
            <div class="row d-flex justify-content-between align" >
                <div class="col-md-2 col-12 text-white free-to-use text-center mb-md-0 mb-4" >
                    <h3 >100%</h3>
                    <span ><span class="color-warning" >Free</span> to Use</span>
                </div>
                <div class="col-md-3 col-12 text-white free-to-use text-center mb-md-0 mb-4" >
                    <h3 >Local</h3>
                    <span class="color-warning" >Takeaways / Restaurants</span>
                </div>
                <div class="col-xl-3 col-md-3 col-12 text-white free-to-use text-center mb-md-0 mb-4" >
                    <h3 >
                        <span class="color-warning" >Instant</span>
                        SMS / <br class="d-lg-block d-none" />Email
                        <span class="color-warning" >Confirmation</span>
                    </h3>
                    
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 text-white free-to-use text-center" >
                    <h3 >
                        <span class="color-warning small" >Pay By </span>
                        Cash, Card
                        <span class="color-warning small" > or </span>
                        Paypal
                     </h3>
                </div>
            </div>
        </div>
    </section>

    {Array.isArray(popularCategories) ?
<section className="section o2e-pc trr-container">
    <div class="section-heading text-center mb-5" >
                <div class="divider mb-2" >
                    <div class="inner-divider" ></div>
                </div>
                <h1 class="title" >Popular Categories</h1>
                <h3 class="secondary-title">Cum doctus civibus efficiantur in imperdiet deterruisset</h3>
            </div>

            <div className="container">
    <OwlCarousel id="o2ePCarousel" options={options}>
 {popularCategories.map((x) => {
    
    return(
        <>
        <a href={`takeaway/${x.id}`}>
    <div class="owl-cat-item overflow-hidden rounded position-relative">
                    <img class="owl-cat-img" alt="popularcategory image" src={x.image_url} />
                    <div class="owl-cat-content d-flex flex-fill">
                        <div class="owl-down-text mt-auto px-3 pb-3 pt-5 w-100">
                            <h4 >{x.title}</h4>
                        </div>
                    </div>
                    </div>
                    </a>
        </>
    )
 }
 )
}

</OwlCarousel>
    </div>
    
    </section>
: ''}
  <section className="section o2e-why-use-us pt-0">
        <div className="container">
            <div className="why-us-text text-center">
                <h3 className="mb-4">WHY USE US?</h3>
                <p>Order 2 Eat offers a simple ordering service. We offer a safe &amp; secure service with high levels of privacy.</p>
                <p className="m-0">We work in close collaboration with your local takeaways/restaurants in lowering prices to save your time and money.</p>
            </div>
        </div>
    </section>
    <section className="section o2e-how-it-work text-center">
        <div className="container">
            <div className="row">
                <div className="col-12 mb-4">
                    <h3 className="color-warning">HOW IT WORKS?</h3>
                    <h5 className="text-white">its easy as ABC</h5>
                </div>
            </div>
            <div className="row">
                <div className="col-sm text-center mb-md-0 mb-4">
                    <img className="img-fluid" alt="Search" src="./images/search.png" />
                    <h4 className="text-white m-0">Search</h4>
                </div>
                <div className="col-sm text-center mb-md-0 mb-4">
                    <img className="img-fluid" alt="Search" src="./images/order.png" />
                    <h4 className="text-white m-0">Order</h4>
                </div>
                <div className="col-sm text-center">
                    <img className="img-fluid" alt="Search" src="./images/eat.png" />
                    <h4 className="text-white m-0">Eat</h4>
                </div>
            </div>
        </div>
    </section><section className="section o2e-type-sms text-center position-relative d-flex align-items-center justify-content-center">
        <div className="confirm-sms py-lg-5">
            <div className="container d-flex h-100">
                <div className="row align-items-center h-100">
                    <div className="col-lg-7 px-md-4">
                        <p>You will receive an Instant SMS &amp; Email from the takeaway / restaurant confirming your order has been processed and estimated delivery / collection time.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>


    {Array.isArray(topRated) ?

            <section className="02e-trr">
    <div class="trr-container top-rate">
            <div class="d-md-flex align-items-center mb-5" >
                <div class="section-heading section-heading-left text-left w-100" >
                    <div class="divider mb-2" >
                        <div class="inner-divider" ></div>
                    </div>
                    <h1 class="title" >Top Rated Resturents</h1>
                    <h3 class="secondary-title" >Cum doctus civibus efficiantur in imperdiet deterruisset</h3>
                </div>
                <div class="view-all-btn" >
                    <button type="button" class="btn btn-text text-danger view-all-btn" ><Link to='/takeaways'>View All</Link></button>
                </div>
            </div>

            <div class="row" >

            {topRated.map((x) => {
                return(
                    <>
                    <div class="col-lg-3 col-md-4 col-sm-6 mb-4" >
                    <a href={`/takeaway/${x.id}`}>
                    <div class="card o2e-card text-white p-0 border-0 rounded overflow-hidden top-img" >
                        <img src={x.image_url} class="card-img" alt="Resturent image" />
                        <div class="card-img-overlay p-3 d-flex align-items-baseline flex-column" >
                            
                            <div class="o2e-trr-bottom mt-auto mr-auto align-self-end" >
                                <h4 >{x.title}</h4>
                                
                            </div>
                        </div>
                    </div>
                    </a>
                </div>
                    </>
                )
            })}
                
                
                
            </div>
        </div>
        </section> : ''}

        {Array.isArray(mostPopular) ?
        <>
        <section class="section o2e-cmp bg-image" >
        <div class="container" >
            <div class="section-heading section-heading-white text-center mb-5" >
                <div class="divider mb-2" >
                    <div class="inner-divider" ></div>
                </div>
                <h1 class="title" >Choose From Most Popular</h1>
                <h3 class="secondary-title" >All the top restaurant in your city</h3>
            </div>
        </div>
    </section>


    
    <div className="trr-container">
    <OwlCarousel id="o2e-cmp-carousel" options={options2}>

        
    {mostPopular.map((x) => {
                return(
                    <>
                    <a href={`/takeaway/${x.id}`} >
                        <div class="o2e-cmp-item" >
                    <img class="" alt="Natural" src={x.image_url} />
                </div>
                </a>
                    </>
                )})}

          
</OwlCarousel>
</div>
</>
: '' }


    <section className="section o2e-fdo bg-image">
    <div className="container">
    <div className="row">
    <div className="col-md-12 col-lg-6 col-12 mb-5 order-lg-2 d-flex justify-content-center">
                    <img className="img-fluid" src="./images/body-2nd-pic.png" alt="" />
                </div>

                <div className="col-md-6">
                    <div className="section-heading section-heading-white text-left mb-5">
                        <h1 className="title mb-4">Find Favorites and Discover New Ones</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rutrum, ante nec hendrerit blandit, neque mauris eleifend odio, nec mollis dolor urna nec neque.</p>
                        <h3 className="secondary-title">Download the App and Place<br className="d-none d-md-block" />Your Order</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rutrum, ante nec hendrerit blandit, neque mauris eleifend odio, nec mollis dolor urna nec neque.</p>
                        <div id="download-background">
                            <img src="./images/download-background.png" alt="" className="img-fluid" />
                        </div>
                    </div>
                    <div className="d-flex flex-custom">
                         
                            <img className="img-fluid" src="./images/AppStore-button.png" alt="App Store" />
                         
                         
                            <img className="img-fluid" src="./images/playstore-button.png" alt="Google Play Store" />
                         
                    </div>
                </div>
        </div>


    </div>
    </section>


</div>
   </>
        );

};
export default Home;