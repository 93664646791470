import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { Rating } from "react-simple-star-rating";
import iconfacebook from "../../images/icon-facebook.png";
import iconinstagram from "../../images/icon-instagram.png";
import icontwitter from "../../images/icon-twitter.png";

import {
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
} from "react-share";
import feedBelly from "../../images/feedabelly-image.jpg";
import SnackBar from "my-react-snackbar";
import { useHistory } from "react-router-dom";
import Header from '../layouts/Header'

const authAxios = global.authAxios;

export default function Confirmation() {
  let history = useHistory();
  const { id } = useParams();
  const [order, setOrderData] = useState();
  const [rating, setRating] = useState(0);
  const [fullName, setFullName] = useState();
  const [comments, setComments] = useState();
  const [reviewSnackbar, setReviewSnackbar] = useState();
  const [takeaway, setTakeawayData] = useState([]);
  const [review, setReview] = useState();

  const redirectReview = () => {
    history.push(`/takeaway/${localStorage.getItem("takeaway_id")}`);
  };
  const onNameChange = (e) => {
    setFullName(e.target.value);
  };

  const onCommentChange = (e) => {
    setComments(e.target.value);
  };

  useEffect(() => {
    getOrderData();
    loadTakeaway();
  }, []);

  const loadTakeaway = async () => {
    const res = await authAxios.get(
      `/loadTakeaway/${localStorage.getItem("takeaway_id")}`
    );
    setTakeawayData(res.data.takeaway);
  };


  const getOrderData = async () => {
    const res = await authAxios.get(`/loadOrder/${id}`);
    setOrderData(res.data);
  };

  const handleRating = (rate) => {
    if (rate === 100) {
      setRating(5);
    }
    if (rate === 80) {
      setRating(4);
    }
    if (rate === 60) {
      setRating(3);
    }
    if (rate === 40) {
      setRating(2);
    }
    if (rate === 20) {
      setRating(1);
    }
    
  };

  const submitReview = async () => {
    const URL = "/reviewPlace";
    const reviewData = {
      order_id: id,
      full_name: fullName,
      comments: comments,
      score: rating,
    };
    await authAxios.post(URL, reviewData).then((response) => {
      if (response.data.message === "success") {
        setReviewSnackbar(true);
        setReview(true);
      }
    });
  };
  return (
    <>
          <Header />
      <div>
        <SnackBar
          open={reviewSnackbar}
          message={"Thank you for your review"}
          position="top-right"
          type="success"
          yesLabel="Ok"
          closeOnClick={false}
          onYes={() => setReviewSnackbar(false)}
        />
      </div>

      <section className="section py-0 position-relative">
        <div className="container">
          <div className="row">
            <div className="col-md mb-4 mb-lg-0">
               <div id="nce-menu-item" className="nce-search-single chk_visibility">
                <div className="row">
                  <div className="col-lg-10 col-sm-12">
                    <div className="nce-search-single-left">
                      <h2> {takeaway ? takeaway.title : ""}</h2>
                      <div className="row nce-hidden-mobile">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                          <ul className="nce-search-single-nav">
                            <li>
                              <div className="nce-img-icon">
                                <img
                                  src="https://www.justorder2eat.co.uk/public/front/assets/images/map-icon.png"
                                  alt="map"
                                />
                              </div>
                              {takeaway ? takeaway.address : ""}{" "}
                            </li>
                            <li>
                              <div className="nce-img-icon">
                                <img
                                  src="https://www.justorder2eat.co.uk/public/front/assets/images/umbrella-icon.png"
                                  alt="umbrella"
                                />
                              </div>
                              {takeaway ? takeaway.second_name : ""}{" "}
                            </li>
                            <li>
                              <div className="nce-img-icon">
                                <img
                                  src="https://www.justorder2eat.co.uk/public/front/assets/images/mobile-icon.png"
                                  alt="mobile"
                                />
                              </div>
                              {takeaway ? takeaway.phone : ""}
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                          <ul className="nce-search-single-nav">
                            <li>
                              <div className="nce-img-icon">
                                <img
                                  src="https://www.justorder2eat.co.uk/public/front/assets/images/clock-icon.png"
                                  alt="clock"
                                />
                              </div>
                              {takeaway ? takeaway.delivery_time_from : ""}{" "}
                            </li>
                            <li>
                              <div className="nce-img-icon">
                                <img
                                  src="https://www.justorder2eat.co.uk/public/front/assets/images/cart-icon.png"
                                  alt="cart"
                                />
                              </div>
                              Min Delivery:{" "}
                              <span>
                                {" "}
                                £ {takeaway ? takeaway.min_order : ""}{" "}
                              </span>
                            </li>
                            <li>
                              <div className="nce-img-icon">
                                <img
                                  src="https://www.justorder2eat.co.uk/public/front/assets/images/delivery-icon.png"
                                  alt="delivery"
                                />
                              </div>
                              Delivery:{" "}
                              <span>{takeaway ? takeaway.delivery : ""} </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                          <ul className="nce-search-single-nav">
                            <li>
                              <div className="nce-img-icon">
                                <img
                                  src="https://www.justorder2eat.co.uk/public/front/assets/images/love-icon.png"
                                  alt="love"
                                />
                              </div>
                              Feed a belly opted in: <span>Yes</span>
                            </li>
                            <li>
                              <div className="nce-img-icon">
                                <img
                                  src="https://www.justorder2eat.co.uk/public/front/assets/images/select-icon.png"
                                  alt="select"
                                />
                              </div>
                              Food Hygiene Rating:{" "}
                              <span>
                                {takeaway ? takeaway.food_hygiene_rating : ""}{" "}
                              </span>
                            </li>
                            <li>
                              <div className="nce-img-icon">
                                <img
                                  src="https://www.justorder2eat.co.uk/public/front/assets/images/clock-icon.png"
                                  alt="clock"
                                />
                              </div>
                              {takeaway ? takeaway.collection_time_from : ""}
                            </li>
                          </ul>
                        </div>
                      </div>
                      
                    </div>
                  </div> 

                  <div className="col-lg-2 nce-hidden-mobile">
                    <div className="nce-search-single-client-right">
                      <a href={global.frontEndUrl + 'takeaway' + '/' + localStorage.getItem("takeaway_id")}>
                        {" "}
                        <img
                        className="width-large"
                          src={takeaway ? takeaway.image_url : ""}
                          alt="takeaway"
                        />
                      </a>
                      <div className="nce-search-single-review">
                        <a
                          className="nce-search-review-link"
                          onClick={() => redirectReview()}
                        >
                          {" "}
                          {takeaway ? takeaway.reviews : ""} reviews
                        </a>
                        <br />

                        <img src="https://www.justorder2eat.co.uk/public/front/images/greenstar-full.png" alt="green"/>
                        <img src="https://www.justorder2eat.co.uk/public/front/images/greenstar-full.png" alt="green"/>
                        <img src="https://www.justorder2eat.co.uk/public/front/images/greenstar-full.png" alt="green"/>
                        <img src="https://www.justorder2eat.co.uk/public/front/images/greenstar-full.png" alt="green"/>
                        <img src="https://www.justorder2eat.co.uk/public/front/images/greenstar-full.png" alt="green"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card o2e-card o2e-commentcard p-4 mb-5">
                <div className="card-header p-0 border-0 bg-transparent">
                {order ? order.order.is_review_placed === 0 ? 
                  <h3 className={review ? "card-title display-none" : "card-title"}>
                    Write your first review
                  </h3> : '' : ''}
                </div>
                <div className="card-body p-0">
                  {order ? order.order.is_review_placed === 0 ?  
                  <form
                    className={
                      review ? "o2ecomentform display-none" : "o2ecomentform"
                    }
                  >
                    <div className="form-group">
                      <label className="text-uppercase px-2" htmlFor="o2ereviewInput">
                        You're Reviewing:
                      </label>
                      <input
                        type="email"
                        className="form-control border-0 rounded-0"
                        id="o2ereviewInput"
                        placeholder="Name"
                        onChange={(e) => onNameChange(e)}
                      />
                    </div>
                    <div className="form-group">
                    <div className="d-flex flex-column wd">
                      <label
                        className="text-uppercase px-2 m-0"
                        htmlFor="o2eCommentReview"
                      >
                        Your Rating*
                      </label>
                      <Rating
                        onClick={handleRating}
                        ratingValue={rating} /* Available Props */
                      />
                      </div>
                      <textarea
                        className="form-control border-0 rounded-0"
                        id="o2eCommentReview"
                        rows="5"
                        onChange={(e) => onCommentChange(e)}
                        placeholder=""
                      ></textarea>
                    </div>
                    <div className="form-group text-center">
                      <button
                        type="button"
                        className="btn btn-md border-0 btn-warning text-center px-5 py-2"
                        onClick={submitReview}
                      >
                        Submit Your Review
                      </button>
                    </div>
                  </form> : '' : ''}
                  <div className="o2e-socialmedia mt-4 text-center">
                    <h5 className="mb-3">Share your takeaway order with friends</h5>
                    <a href="#" className="btn p-0 mx-1 btn-fasebook">
                      
                      <FacebookShareButton url={global.frontEndUrl} quote={'Just ordered my takeaway from ORDER 2 EAT'}>
                      <img  alt="Facebook" src={iconfacebook} />
                        </FacebookShareButton>
                    </a>
                    <a href="#" className="btn p-0 mx-1 btn-twitter">
                      
                      <InstapaperShareButton url={global.frontEndUrl} quote={'Just ordered my takeaway from ORDER 2 EAT'}>
                      <img  alt="Twitter" src={iconinstagram} />
                        </InstapaperShareButton>
                    </a>
                    <a href="#" className="btn p-0 mx-1 btn-instagram">
                      
                      <TwitterShareButton url={global.frontEndUrl} quote={'Just ordered my takeaway from ORDER 2 EAT'}>
                      <img  alt="Instagram" src={icontwitter} />
                        </TwitterShareButton>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card o2e-card o2e-feedabelly border-0 p-0 mb-3 b">
                <h6>What is feed a belly?</h6>
                <h4>Order with Order 2 Eat & FEED A BELLY</h4>
                <p>
                  Help a child needy, while enjoying your takeaway. You can make
                  a difference. We at Order 2 EAT as a socially responsible
                  company have set up this scheme & you can be a part of it, by
                  simply ordering through our site, at no extra cost. Every time
                  you order, we contribute to this scheme & help feed a hungry
                  belly.
                </p>
                <img className="img-fluid" alt="Feed a Belly" src={feedBelly} />
                <h3>Visit our website:</h3>
                <a href="http://www.feedabelly.co.uk" className="link">
                  http://www.feedabelly.co.uk/
                </a>
              </div>
            </div>
            <div className="col-lg-auto sidebar">
              <div className="sidebar-content o2e-checkout-details">
                <div className="o2e-card-header widget-bg overflow-hidden">
                  <div className="o2e-widget-title bg-red text-center p-3">
                    <h6>Thank you we have received your order</h6>
                  </div>
                  <div className="o2e-discount-text bg-black text-center p-2 d-flex justify-content-between">
                    <div className="">Order Details</div>
                    <div className="">
                      Order Type {order ? order.order.delivery_method : ""}
                    </div>
                  </div>
                </div>
                <div className="o2e-cart-content">
                  <div className="o2echeckout-header text-right p-2">
                    <h3>Payed by: COD</h3>
                  </div>
                  <div className="o2ecart-item-body p-2">
                    <div className="o2euser-details mb-3">
                      <ul className="nav flex-column">
                        <li>Email: {order ? order.order.cemail : ""}</li>
                        <li>Name: {order ? order.order.cname : ""}</li>
                        <li>Phone# {order ? order.order.cphone : ""}</li>
                        <li>Address: {order ? order.order.caddress : ""}</li>
                        <li>Comment: {order ? order.order.ccomments : ""}</li>
                      </ul>
                    </div>

                    {order
                      ? order.items.map((x, index) => {
                          return (
                            <div key={index}>
                              <div className="o2ecart-item d-flex align-items-center mb-1">
                                <div className="o2ecart-item-name p-1 flex-fill">
                                  {x.qty} x {ReactHtmlParser(x.name)}
                                </div>
                                <div className="o2ecart-item-price p-1">
                                  £{x.price}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}

                    <hr className="mx-3" />
                    <div className="o2e-cart-total px-2">
                      <div className="o2e-cartrow d-flex mb-2">
                        <span className="o2e-cart-totaltext subtotal">
                          Sub-Total
                        </span>
                        <span className="o2e-cart-totaltext totalprice ml-auto">
                          £{order ? order.order.subtotal : ""}
                        </span>
                      </div>
                      <div className="o2e-cartrow d-flex mb-2">
                        <span className="o2e-cart-totaltext subtotal">
                          Discount:
                        </span>
                        <span className="o2e-cart-totaltext discountprice ml-auto">
                          £{order ? order.order.discount : ""}
                        </span>
                      </div>
                      <div className="o2e-cartrow d-flex mb-2">
                        <span className="o2e-cart-totaltext subtotal">
                          Delivery Fee:
                        </span>
                        <span className="o2e-cart-totaltext discountprice ml-auto">
                          £{order ? order.order.delivery_amount : ""}
                        </span>
                      </div>
                    </div>
                    <div className="o2e-cart-mastertotal p-2 mb-0">
                      <div className="o2e-cartrow d-flex">
                        <span className="o2e-cart-totaltext subtotal">TOTAL</span>
                        <span className="o2e-cart-totaltext discountprice ml-auto">
                          £{order ? order.order.order_total : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
