import './App.css';

import "./components/pages/Global";
import "./components/FontawesomeIcons";
import {useEffect} from 'react';
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Takeaways from "./components/pages/Takeaways";
import Menu from "./components/pages/Menu";
import Footer from "./components/layouts/Footer";
 
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import NotFound from "./components/pages/NotFound";
import Checkout from './components/pages/Checkout';
import Login from './components/pages/Login';
import Confirmation from './components/pages/Confirmation';
import ForgotPassword from './components/pages/forgotPassword';
import Register from './components/pages/Register';
import Dashboard from './components/pages/Dashboard';
import { Refer } from './components/pages/referlocaltakeaway';
import ScrollToTop from './components/pages/ScrollToTop';
import { Terms } from './components/pages/terms';


 
const authAxios =  global.authAxios;
 

function App(props) {
   //setIP(res.data.IPv4)
   
   

    const getData = async () => {
      const res = await authAxios.get('/initOte')
      
      localStorage.setItem('ote_toke', res.data.token);
      localStorage.setItem('ote_country', res.data.country);
      
      
   
}

  useEffect( () => {
    var token = localStorage.getItem('ote_toke');
    //var country = localStorage.getItem('ote_country');

    if(token === null){
      getData()
    }
    

  }, [])

  
  return (
    
    <Router>
      <div className="App">
        <ScrollToTop />
       
      <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/takeaways" component={Takeaways} />
          <Route exact path="/takeaway/:id" component={Menu} />
          <Route exact path="/checkout/:id" component={Checkout} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/success/:id" component={Confirmation} />
          <Route exact path="/forgot_pass" component={ForgotPassword} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/refer" component={Refer} />
          <Route exact path="/terms" component={Terms} />
          <Route component={NotFound} />
        </Switch>
        <Footer/>
        </div>
    </Router>
    
  );
}

export default App;
