import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "react-js-pagination";
import Header from "../layouts/Header";
const authAxios = global.authAxios;

const Takeaways = () => {
  var searchVal;

  let foodArr = [];
  let specialsArr = [];

  const [takeways, setTakeawayData] = useState([]);
  const [filters, setFiltersData] = useState([]);

  useEffect(() => {
    loadFilters();
    loadTakeaways();
  }, []);

  const loadTakeaways = async (pageNumber = 1) => {
    var url =
      "/takeaways?page=" +
      pageNumber +
      "&post_code" +
      "=" +
      `${localStorage.getItem("post_code")}`;

    if (searchVal) {
      url =
        "/takeaways?page=" +
        pageNumber +
        "&post_code" +
        "=" +
        `${localStorage.getItem("post_code")}` +
        "&title=" +
        searchVal;
    }

    const result = await authAxios.get(url);
    setTakeawayData(result.data);
  };

  const loadFilters = async () => {
    var url = "/filters";
    const result = await authAxios.get(url);
    setFiltersData(result.data);
  };

  const searchChanged = (event) => {
    searchVal = event.target.value;
    loadTakeaways();
  };

  const handleSpecialsChange = (event) => {
    var isChecked = event.target.checked;
    var item = event.target.value;

    if (isChecked) {
      specialsArr.push(item);
    } else {
      var index = specialsArr.indexOf(item);

      specialsArr.splice(index, 1);
    }
  };

  const handleFoodChange = (event) => {
    var isChecked = event.target.checked;
    var item = event.target.value;

    if (isChecked) {
      foodArr.push(item);
    } else {
      var index = foodArr.indexOf(item);

      foodArr.splice(index, 1);
    }
  };

  const clearFilters = (event) => {
    var chkFilters = document.getElementsByClassName("chk-filters");
    for (var i = 0; i < chkFilters.length; i++) {
      chkFilters[i].checked = false;
    }
    foodArr = [];
    specialsArr = [];
  };

  const removeLocalItems = () => {
    localStorage.setItem("Delivery", "Pre-Order");
    localStorage.setItem("radiovalue", 1);
    localStorage.removeItem("addmoreitem");
    localStorage.removeItem("postcode");
  };

  const renderTakeaways = () => {
    if (takeways.data) {
      const current_page = takeways.current_page;
      const per_page = takeways.per_page;
      const total = takeways.total;
      const rs = takeways.data;
      return (
        <React.Fragment>
          {
            // <div className="o2e-menu-filter d-flex justify-content-between mb-4">
            //                 <div className="o2e-counts">{total} open restaurants</div>
            //               </div>
          }
          <div className="o2e-menu-content">
            {rs.map((tw, index) => (
              <a
                href={`${global.frontEndUrl}takeaway/${tw.id}`}
                className="custom-link"
                onClick={() => removeLocalItems()}
              >
                <div
                  className="o2e-row justify-content-between b-border  p-20"
                  key={index}
                >
                  <div className="o2e-specialoffer">
                    <span className="o2e-nce-sponsor-tag">
                      <p className="blackk">{tw.promotional_discount}</p>
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="o2emenu-name align-items-center pl-80">
                        <h3 className="font-2-8">{tw.title}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="d-a">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="o2emenu-name align-items-center">
                          <ul>
                            <li>
                              <FontAwesomeIcon icon="utensils" />{" "}
                              <a href="# " className="fo-14">
                                {tw.second_name}
                              </a>
                            </li>
                            <li>
                              <FontAwesomeIcon icon="mobile-alt" />{" "}
                              <a href="tel={tw.phone}" className="fo-14">
                                {tw.phone}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="o2emenu-delivery align-items-center">
                          <ul>
                            <li>
                              <FontAwesomeIcon icon="clock" />{" "}
                              <a href="# " className="fo-14">
                                {tw.delivery_time_from}
                              </a>
                            </li>
                            <li>
                              <FontAwesomeIcon icon="utensils" />{" "}
                              <a href="# " className="fo-14">
                                Min. order £{tw.min_order}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="o2emenu-delivery-info align-items-center">
                          <ul>
                            <li className="fo-14">
                              <FontAwesomeIcon icon="clock" /> Delivery{" "}
                              {tw.delivery_time_from}
                            </li>
                            <li>
                              <FontAwesomeIcon icon="map-marker-alt" />{" "}
                              <a href="#" className="fo-14">
                                {tw.address}, {tw.town}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {
                        //      <div className="col-sm-2 mt-50 ">
                        //  </div>
                      }
                    </div>
                    <div className="o2emenu-image text-center align-items-center m-20">
                      {" "}
                      <a href={`${global.frontEndUrl}takeaway/${tw.id}`}>
                        <img src={tw.image_url} alt="ote" />
                      </a>
                      <div className="o2e-rating-menu d-md-block">
                        <div className="o2e-rating-txt">
                          ({tw.reviews}) Reviews
                        </div>
                        <span>
                          <FontAwesomeIcon icon="star" />
                        </span>{" "}
                        <span>
                          <FontAwesomeIcon icon="star" />
                        </span>{" "}
                        <span>
                          <FontAwesomeIcon icon="star" />
                        </span>{" "}
                        <span>
                          <FontAwesomeIcon icon="star" />
                        </span>{" "}
                        <span>
                          <FontAwesomeIcon icon="star" />
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </div>
          <div className="mt-3 justify-content-center">
            <nav className="w-300">
              <Pagination
                activePage={current_page}
                itemsCountPerPage={per_page}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                onChange={(pageNumber) => loadTakeaways(pageNumber)}
                itemclassName="page-item"
                linkclassName="page-link"
                firstPageText="First"
                lastPageText="Last"
              />
            </nav>
          </div>
        </React.Fragment>
      );
    }
  };

  const renderPartners = () => {
    if (filters.partners) {
      const rs = filters.partners;

      return (
        <React.Fragment>
          {rs.map((tw, index) => (
            <div className="col-4 mb-4" key={index}>
              {" "}
              <img
                alt="partner"
                className="img-fluid w-100"
                src={tw.image_url}
              />{" "}
            </div>
          ))}
        </React.Fragment>
      );
    }
  };
  const renderFoods = () => {
    if (filters.foods) {
      const rs = filters.foods;

      return (
        <React.Fragment>
          {rs.map((cu, index) => (
            <div
              className="position-relative side-bar-element w-210"
              key={index}
            >
              <label
                className="form-control-label w-100 p-2 p-6 mb-15 fo-14"
                htmlFor="customCheck2"
              >
                {cu.title}
              </label>
              <div className="custom-control custom-checkbox position-absolute tr">
                <input
                  type="checkbox"
                  className="chk-foods chk-filters black-check"
                  name="foods"
                  value={cu.id}
                  onChange={handleFoodChange}
                />

                <label class="custom-control-label" for="customCheck1"></label>
              </div>
            </div>
          ))}
        </React.Fragment>
      );
    }
  };

  const renderCuisineImages = () => {
    if (filters.searches) {
      const rs = filters.searches;

      return (
        <React.Fragment>
          {rs.map((tw, index) => (
            <div className="col-4 mb-4" key={index}>
              {" "}
              <img
                alt="log"
                className="img-fluid w-100"
                src={tw.image_url}
              />{" "}
            </div>
          ))}
        </React.Fragment>
      );
    }
  };
  const renderSpecials = () => {
    if (filters.specials) {
      const rs = filters.specials;

      return (
        <React.Fragment>
          {rs.map((sp, index) => (
            <div className="position-relative side-bar-element" key={index}>
              <label
                className="form-control-label w-100 p-2 fo-14"
                htmlFor="customCheck9"
              >
                {sp.title}
              </label>
              <div className="custom-control custom-checkbox rounded position-absolute">
                {
                  // <input type="checkbox" className="chk-specials chk-filters" name="specials" value={sp.id} onChange={handleSpecialsChange} />
                }
                <input
                  type="checkbox"
                  className="chk-foods chk-filters black-check"
                  name="foods"
                  value={sp.id}
                  onChange={handleFoodChange}
                />

                <label
                  class="custom-control-label"
                  htmlFor="customCheck1"
                ></label>
              </div>
            </div>
          ))}
        </React.Fragment>
      );
    }
  };

  return (
    <>
      <Header search={true} />

      <div className="search-container">
        {
          // <div className="section inner-page-header d-md-block d-none"></div>
        }{" "}
        <section
          className="section inner-page-header d-md-block d-none"
          style={{ minHeight: "164px;" }}
        ></section>
        <section className="section sreach_bar  pt-3 pb-3 mb-4 py-2 d-flex align-items-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md mb-md-0 mb-4 d-none d-md-block">
                <div className="change-location">
                  <span className="d-block text-white pl-12 t-i">
                    <strong className="text-uppercase">mk40nr</strong>, before
                  </span>
                  <small className="text-yellow d-flex justify-space-around fs fo-12">
                    change location
                  </small>
                </div>
              </div>
              <div className="col-md-9 ml-10">
                <form className="search-item-form d-flex flex-wrap bg-white mb-0 rounded">
                  <div
                    className="form-row align-items-center flex-fill b-color"
                    id="searchitemInput"
                  >
                    <div className="col">
                      <input
                        onChange={searchChanged}
                        type="text"
                        className="form-control pr-3 border-0"
                        id="searchitemInput"
                        placeholder="Search for a dish or restaurant"
                      />
                    </div>
                    <div className="col-auto d-md-block d-none">
                      <button
                        type="button"
                        id="submit"
                        className="btn-submit btn text-uppercase px-4 text-white"
                        onClick={loadTakeaways}
                      >
                        Quick Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {
          //   <div id="nce-search">
          // 	<div className="container search-container inner-search-container">
          // 		<div className="row">
          // 			<div className="col-lg-12">
          // 				<div className="nce-search-area">
          // 					<div className="nce-search-form">
          // 						<span className="nce-search-icon"></span>
          // 						<input type="text" onChange={searchChanged} name="txt_title" placeholder="Search by your favourite takeaway / restaurant" className="ote_mob_search_text" />
          // 						<button type="button" id="submit" className="nce-submit-btn ote-search-button" onClick={loadTakeaways} >Search</button>
          // 					</div>
          // 				</div>
          // 			</div>
          // 		</div>
          // 	</div>
          // </div>
        }
      </div>
      <div className="main-search-content">
        <div
          className="container search-container p0-container"
          style={{ maxWidth: "100% !important" }}
        >
          <div className="section pb-0 pt-4 ml-35">
            <div className="container search-container search-menu-container p0-container">
              <div className="row main-search-content ">
                <div className="col-lg-3 col-md-12 left-sidebar search-cuisines">
                  <div className="sidebar-widget sidebar-heading">
                    <h3 className="fo-24">
                      Filters
                      <button
                        type="button"
                        className="btn p-0 clearall"
                        onClick={clearFilters}
                      >
                        Clear all
                      </button>
                    </h3>
                  </div>
                  <div className="sidebar-widget mb-4 mb-md-5 nce-search-cuisines">
                    <div className="sidebar-widget sidebar-heading mb-4">
                      <h3 className="p-6">Cuisines</h3>
                    </div>
                    <div className="sidebar-widget-content">
                      <form className="widget-form">{renderFoods()}</form>
                    </div>
                  </div>
                  <div className="sidebar-widget mb-4 mb-md-5">
                    <div className="sidebar-widget sidebar-heading mb-4 t-color">
                      <h3 className="p-6">Specials</h3>
                    </div>
                    <div className="sidebar-widget-content">
                      <form className="widget-form">{renderSpecials()}</form>
                    </div>
                  </div>
                  <div className="sidebar-widget mb-4 mb-md-5">
                    <div className="sidebar-widget sidebar-heading mb-4 t-color">
                      <h3 className="p-6">Our Partners</h3>
                    </div>
                    <div className="sidebar-widget-content">
                      <div className="row">{renderPartners()}</div>
                    </div>
                    <div className="see-all-btn">
                      <button type="button" className="btn p-0 fo-16 f-p">
                        View all
                      </button>
                    </div>
                  </div>
                  <div className="sidebar-widget mb-4 mb-md-5">
                    <div className="sidebar-widget sidebar-heading mb-4">
                      <h5 className="p-6 ta-c">SEARCH BY CUISINES</h5>
                    </div>
                    <div className="sidebar-widget-content">
                      <div className="row">{renderCuisineImages()}</div>
                    </div>
                    <div className="see-all-btn">
                      <button type="button" className="btn p-0 fo-16 f-p">
                        View all
                      </button>
                    </div>
                  </div>
                  <div className="sidebar-widget mb-4 mb-md-5">
                    <div className="sidebar-widget sidebar-heading mb-4">
                      <h3 className="p-6 ta-c bgc-n fo-26">HOT DEALS</h3>
                    </div>
                    <div className="sidebar-widget-content">
                      <div className="sidebar-owlcarousel owl-carousel owl-theme display-block">
                        <div className="sidebar-owlcarousel-item">
                        <a href={`${global.frontEndUrl}takeaway/${filters.offer_image ? filters.offer_image.tw_id : '#'}`}>
                          <img
                            alt="offer"
                            className="img-fluid w-100"
                            src={filters.offer_image ? filters.offer_image.image_url : ''}
                          />
                          </a>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>

                <div className="col-lg-9 col-md-12 order-md-2">
                  <div className="o2e-menu-container">{renderTakeaways()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Takeaways;
