import React, { useState, useEffect , useRef} from "react";
import { useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import SnackBar from "my-react-snackbar";
import LoadingGif from "../../images/loading-waiting.gif";
import checkImage from "../../images/check.png";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";
import Header from '../layouts/Header';
import SecureLS from "secure-ls";
import { Link } from "react-router-dom";
var ls = new SecureLS({encodingType: 'aes'});

var searchVal = "";
var selectedTab = "pills-menu";
var selectedCat = "1";

const authAxios = global.authAxios;

const Menu = () => {
  const ref = useRef(null);
  let history = useHistory();
  const { id } = useParams();
  const [takeaway, setTakeawayData] = useState([]);
  const [categories, setCategoriesData] = useState([]);
  const [items, setItemsData] = useState([]);
  const [sitems, setSearchedItemsData] = useState([]);
  const [reviews, setReviewData] = useState([]);
  const [cart, setCartData] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [selected, setSelected] = useState({});
  const [selectedChildren, setSelectedChildren] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loader, setLoader] = useState({});
  const [removeLoader, setRemoveLoader] = useState({});
  const [deliveryModal, setDeliveryModal] = useState(false);
  const [removeItemSnackbar, setRemoveItemSnackbar] = useState(false);
  const [postcodeInput, showPostCodeInput] = useState(true);
  const [postCodeValue, setPostCodeValue] = useState("");
  const [takewayErrorMessage, setTakeawayErrorMessage] = useState();
  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [radioselected, setRadioSelected] = useState(1);
  const [closeResturantSnackbar, setcloseResturantSnackbar] = useState(false);
  const [totalItemAmount, setTotalAmount] = useState(0);
  const [popupItems, setPopUpItems] = useState([]);
  const [deliveryData, setDeliveryData] = useState();
  const [minOrder, setMinOrder] = useState(false);
  const [singleItemPrice, setItemPrice] = useState();
  const [childrenItems, setChildrenItems] = useState([]);
  const [drop, setOpenDrop] = useState(false)
  const [side, setSideBar] = useState(false)
  const [catactiveId,setCatActiveId] = useState()
  const [cartHeader, setCartHeader] = useState(false)
const [activeCat, setActiveCat] = useState(false)
  const [FinalItemPrice , setFinalItemPrice] = useState('0')
  const [redPlus , setRedPlus] = useState(false)
  const [postCodeVal , showpostcode] = useState(true)

  const myRef = useRef(null)
  const errorRef = useRef(null)

  const executeScroll = () => {
    
    myRef.current.scrollIntoView() 
  }

  useEffect(() => {
    
    setRadioSelected(parseInt(localStorage.getItem('radiovalue')))

    if(parseInt(localStorage.getItem('radiovalue')) === 2){
      showpostcode(false)
    }
  }, []);

  const openDropdown = () => {
    setOpenDrop(!drop);
    setActiveCat(false)
  }

  const openSideBar = () => {
    setSideBar(!side);
    setActiveCat(false)
  }


  useEffect(() => {
    localStorage.removeItem('popup')
    
  },[])

  const handleClick =
    (index, parentIndex, itemPrice, itemid, children) => () => {
      if (popupItems.length > 0) {
        var found = popupItems.some((el) => el.parent === parentIndex);
        var childFound = popupItems.some((el) => el.child === index);
      }

      if (found && childFound === false) {
        var copyArray = [...popupItems];
        var objIndex = copyArray.findIndex((obj) => obj.parent === parentIndex);
        copyArray[objIndex].child = index;
        copyArray[objIndex].price = itemPrice;
        setPopUpItems(copyArray);
      } else if (found && childFound) {
        var copyArrayChild = [...popupItems];
        var copyArraySubChildren = [...childrenItems];
        var filterArrayChild = copyArrayChild.filter(
          (obj) => obj.child !== index
        );

        var filterArraySubChidren = copyArraySubChildren.filter(
          (x) => x.childParent !== index
        );
        setChildrenItems(filterArraySubChidren);
        setPopUpItems(filterArrayChild);
      } else {
        setPopUpItems((oldArray) => [
          ...oldArray,
          {
            item_id: itemid,
            parent: parentIndex,
            child: index,
            price: itemPrice,
            children: children,
          },
        ]);
      }

    };

    

  useEffect(() => {
    const popupItemsCopy = [...popupItems];
    popupItemsCopy.push({ price: singleItemPrice });

    if (popupItemsCopy.length > 0) {
      var totalAmount = popupItemsCopy.reduce(
        (n, { price }) => n + parseFloat(price),
        0
      );

      var totalChildrenAmount = childrenItems.reduce(
        (n, { price }) => n + parseFloat(price),
        0
      );

      var finalItemsAmount =
        parseFloat(totalAmount) + parseFloat(totalChildrenAmount);

      var re3 = new RegExp("^-?\\d+(?:.\\d{0," + (2 || -1) + "})?");
      if (finalItemsAmount) {
        var finalAmount = finalItemsAmount.toString().match(re3);
        setTotalAmount(finalAmount);
      }

      var myAr = {};
      popupItems.map((x) => {
        return myAr[x.child] = true;
      });

      var checkChildren = popupItems.filter((x) => {
        return x.children === true;
      });

      var myCHildrenArr = {};
      checkChildren.map((x) => {
        return myCHildrenArr[x.child] = true;
      });

      setSelectedChildren(myCHildrenArr);
      setSelected(myAr);
    }
  }, [popupItems, singleItemPrice, childrenItems]);

  const onChange1 = () => {
    localStorage.setItem("Delivery", "Pre-Order");
    localStorage.setItem("radiovalue", 1);
    setInputValue1(inputValue1);
    setRadioSelected(1);
    showpostcode(true)

  };
  const onChange2 = () => {
    localStorage.setItem("Delivery", "Collection");
    localStorage.setItem("radiovalue", 2);
    setInputValue2(inputValue2);
    setRadioSelected(2);
    showpostcode(false)
    
  };

  const onPostCodeChange = (event) => {
    setPostCodeValue(event.target.value);
  };

  const changePostCode = async () => {
    var URL = "/updatePostcode";
    var postCode = { takeaway_id: takeaway.id, postcode: postCodeValue };
    await authAxios.post(URL, postCode).then((response) => {
      if (response.data.status === "fail") {
        setTakeawayErrorMessage(response.data.msg);
      } else if (response.data.status === "success") {
        showPostCodeInput(false);
        setTakeawayErrorMessage();
        localStorage.setItem("postcode", postCodeValue);
      }
    });

    const deliveryData = {
      takeaway_id: id,
      is_delivery: radioselected === 1 ? 1 : 0,
      subtotal: cart.subtotal,
      payment_gateaway: "COD",
      postcode: postCodeValue,
    };
    var calculateDeliveryCharges = "/calculateDeliveryCharges";
    await authAxios
      .post(calculateDeliveryCharges, deliveryData)
      .then((response) => {
        if (response.data.status === "success") {
          setDeliveryData(response.data);
        }
      });
  };

  const onCloseModal = () => setOpen(false);
  const onCloseDeliveryModal = () => setDeliveryModal(false);

  useEffect(() => {


    
    if (localStorage.getItem("postcode")) {
      showPostCodeInput(false);
      setPostCodeValue(localStorage.getItem("postcode"));
    
  }

  if(localStorage.getItem('addmoreitem') === "true"){
    setCartHeader(true)
  }
    loadTakeaway();
    loadCart();
    loadReviews();
  }, []);

  const redirectLogin = () =>{
    history.push('/login')
  }

  useEffect(() => {
    const deliveryData = {
      takeaway_id: id,
      is_delivery: radioselected === 1 ? 1 : 0,
      subtotal: cart.subtotal,
      payment_gateaway: "COD",
      postcode: postCodeValue,
    };
    var calculateDeliveryCharges = "/calculateDeliveryCharges";
    authAxios.post(calculateDeliveryCharges, deliveryData).then((response) => {
      if (response.data.status === "success") {
        setDeliveryData(response.data);
      }
    });
  }, [totalItemAmount, cart, radioselected]);

  const showInput = () => {
    showPostCodeInput(true);
  };

  const loadCart = async () => {
    const res = await authAxios.get(`/cartContent/${id}`);
    setCartData(res.data);
  };

  const loadTakeaway = async () => {
    const res = await authAxios.get(`/loadTakeaway/${id}`);
    setTakeawayData(res.data.takeaway);
    setCategoriesData(res.data.cats);


    
    loadItems(selectedCat);
  };

  const loadReviews = async (pageNumber = 1) => {

    var ajaxUrl = "/loadReviews/" + id + "?page=" + pageNumber;
    const result = await authAxios.get(ajaxUrl);
    setReviewData(result.data);
  };

  const loadItems = async (cat_id = 0) => {
    cat_id = cat_id.replace("catId_", "");

    var ajaxUrl =
      "/items?cat_id=" + cat_id + "&tw_id=" + id + "&title=" + searchVal;

    const result = await authAxios.get(ajaxUrl);

    if (searchVal === "") {
      setItemsData(result.data);
      setSearchedItemsData(result.data);


    } else {
      setSearchedItemsData(result.data);
    }
  };

  const searchChanged = (event) => {
    searchVal = event.target.value;

    loadItems(selectedCat);
  };

  const addToCart = async (e, index, item_id, item_price) => {

    
    localStorage.setItem("popupkey", e.target.id);
    setTimeout(() => {
      setcloseResturantSnackbar(false)
    }, 3000);
    setPopUpItems([]);
    setChildrenItems([]);
    
    setItemPrice(item_price);
    localStorage.setItem("itemprice", item_price);
    if (takeaway.are_we_open.status === 1) {
      if (
        takeaway.is_delivery_start === 1 ||
        localStorage.getItem("popup") === "true" || localStorage.getItem('addmoreitem') === "true"
      ) {
        setOpenSnackbar(false);
        setLoader((state) => ({
          ...state,
          [index]: true,
        }));
        let filteredItems = sitems.menu.filter((x) => {
          return x.id === item_id;
        });

        var exists =
          filteredItems.filter(function (o) {
            return o.hasOwnProperty("options");
          }).length > 0;

          

        if (exists && filteredItems.length > 0) {
          setLoader((state) => ({
            ...state,
            [index]: false,
          }));
          setOpen(true);

          
          // localStorage.setItem("popupkey", filteredItems[0].js_id);
          setModalData(filteredItems[0].options);
          
        } else {
          e.preventDefault();

          var itemId = e.target.id;

          const product = { id: itemId, options: [], takeaway_id: id };

          var URL = "/atc";

          await authAxios.post(URL, product).then((response) => {
            if (response.data.success === 1) {
              setLoader((state) => ({
                ...state,
                [index]: false,
              }));

              setOpenSnackbar(true);
              setCartHeader(true)
            }
            
          });
          loadCart();
        }
      } else if (
        takeaway.is_delivery_start === 0 &&
        localStorage.getItem("popup") !== "true"
      ) {
        localStorage.setItem("item_id", e.target.id);
        setDeliveryModal(true);
      }
    } else {
      setcloseResturantSnackbar(true);
    }

    if( radioselected === 1 && (parseFloat(FinalItemPrice) < parseFloat(takeaway.min_order_delivery))){
      
      setMinOrder(true)
      // setCartHeader(true)
    }
  };

  const updateCart = async (e, index) => {
    setRemoveItemSnackbar(false);
    setRemoveLoader((state) => ({
      ...state,
      [index]: true,
    }));
    var selectedItem = e.target.id;

    const product = { id: selectedItem, takeaway_id: id };

    var URL = "/utc";

    await authAxios.post(URL, product).then((response) => {
      if (response.data.success === 1) {
        setRemoveItemSnackbar(true);
        setRemoveLoader((state) => ({
          ...state,
          [index]: false,
        }));

      }
    });
    loadCart();
  };

    const clearCart = async () => {
      await authAxios.get(`/clearBasket/${id}`);
      setCartHeader(false)
      loadCart();
      localStorage.removeItem('addmoreitem')
    };

  const filterCat = (event) => {
    myRef.current.scrollIntoView({ behaviour:'smooth'}) 
    document.getElementById(`${selectedCat}`).classList.remove("active");
    document.getElementById(`${event.target.id}`).classList.add("active");
    selectedCat = event.target.id;

    setCatActiveId(event.target.id)
    loadItems(selectedCat);

    setActiveCat(true)
    setOpenDrop(false);
    setSideBar(false);


    document.getElementById(selectedTab).classList.remove("active");
    document.getElementById(selectedTab + "-div").style.display = "none";
    document.getElementById('pills-menu').classList.add("active");
    document.getElementById('pills-menu' + "-div").style.display = "block";
  };

  const showTab = (event) => {
    document.getElementById('pills-menu').classList.remove("active");
    document.getElementById('pills-menu' + "-div").style.display = "none"; 

    document.getElementById(selectedTab).classList.remove("active");
    document.getElementById(selectedTab + "-div").style.display = "none";

    document.getElementById(event.target.id).classList.add("active");
    document.getElementById(event.target.id + "-div").style.display = "block";

    
    selectedTab = event.target.id;
  };

  const handleOptionItems = async (option) => {

    if(option === "Pre-Order"){
      localStorage.setItem("Delivery", "Pre-Order");
    localStorage.setItem("radiovalue", 1);
    setRadioSelected(1);
    showpostcode(true)
    }

    else{
      localStorage.setItem("Delivery", "Collection");
    localStorage.setItem("radiovalue", 2);
    setRadioSelected(2);
    showpostcode(false)
    }
    localStorage.setItem("popup", true);
    localStorage.setItem("Delivery", option);
    setDeliveryModal(false);
    let item_id = localStorage.getItem("item_id");
    setLoader((state) => ({
      ...state,
      [item_id]: true,
    }));

    let filteredItems = sitems.menu.filter((x) => {
      return x.js_id === item_id;
    });

    var exists =
      filteredItems.filter(function (o) {
        return o.hasOwnProperty("options");
      }).length > 0;

      

    if (exists && filteredItems.length > 0) {

      
      setLoader((state) => ({
        ...state,
        [item_id]: false,
      }));
      setOpen(true);
      localStorage.setItem("popupkey", filteredItems[0].js_id);
      setModalData(filteredItems[0].options);
    } else {
      var itemId = item_id;

      const product = { id: itemId, options: [], takeaway_id: id };

      var URL = "/atc";

      await authAxios.post(URL, product).then((response) => {
        if (response.data.success === 1) {
          setLoader((state) => ({
            ...state,
            [item_id]: false,
          }));
          setOpenSnackbar(true);
          setCartHeader(true)
        }
      });
      loadCart();
    }
  };

  const addPopUpItems = async () => {
    var cartArray = [];
    var a = popupItems.map((elem) => cartArray.push(elem.child));

    var b = childrenItems.map((elem) =>
      cartArray.push(elem.parent + "_" + elem.quantity)
    );

    var item = popupItems[0].item_id;

    var cartObject = {
      id: localStorage.getItem("popupkey"),
      options: cartArray,
      takeaway_id: id,
    };
    var URL = "/atc";
    var deliveryURL = "/calculateDeliveryCharges";

    await authAxios.post(URL, cartObject).then((response) => {
      if (response.data.success === 1) {
        setOpen(false);
        setOpenSnackbar(true);
      }
    });
    loadCart();
  };

  const placeOrder = () => {

    if(takeaway.is_signup === 1 && (ls.get('user_id') === '' || ls.get('user_id') === null)){
      history.push('/register')
      
    }
    else{
      
    localStorage.setItem("is_delivery_start", takeaway.is_delivery_start);
    localStorage.setItem("takeaway_id", id);

    if (parseFloat(cart.subtotal) > parseFloat(takeaway.min_order)) {
      if (postCodeValue && takeaway.is_delivery_charges === 1) {
        localStorage.setItem('addmoreitem', 'true')
        history.push(`/checkout/${id}`);
      } else if (postCodeValue === "" && radioselected === 2 ) {
        localStorage.setItem('addmoreitem', 'true')
        history.push(`/checkout/${id}`);
      } else if(postCodeValue === "" && takeaway.is_delivery_charges === 0){
        localStorage.setItem('addmoreitem', 'true')
        
        history.push(`/checkout/${id}`);
      }else {
        
        
        
        setTakeawayErrorMessage(
          "Please enter 1st part of your postcode e.g SW12 1BA enter SW12"
        );
        document.getElementById('pt_code').scrollIntoView() 
      }
    }
  }
  };

  const addChildrenQuantity = (id, price, parent) => {
    var regex2 = new RegExp("^-?\\d+(?:.\\d{0," + (2 || -1) + "})?");

    if (childrenItems.length > 0) {
      var found = childrenItems.some((el) => el.parent === id);
    }

    if (found) {
      var copyArray = [...childrenItems];
      var objIndex = copyArray.findIndex((obj) => obj.parent === id);

      copyArray[objIndex].price = (
        parseFloat(copyArray[objIndex].price) + parseFloat(price)
      )
        .toString()
        .match(regex2)[0];
      copyArray[objIndex].quantity = copyArray[objIndex].quantity + 1;

      setChildrenItems(copyArray);
    } else {
      // popuparray.push({parent: parentIndex, child: index , price:itemPrice })
      setChildrenItems((oldArray) => [
        ...oldArray,
        {
          parent: id,
          price: price,
          quantity: 1,
          childParent: parent,
        },
      ]);
    }
  };

  const removeChildrenQuantity = (id, price, parent) => {
    var regex3 = new RegExp("^-?\\d+(?:.\\d{0," + (2 || -1) + "})?");

    if (childrenItems.length > 0) {
      var found = childrenItems.some((el) => el.parent === id);
    }

    if (found) {
      var copyArray = [...childrenItems];
      var objIndex = copyArray.findIndex((obj) => obj.parent === id);

      if (copyArray[objIndex].quantity === 1) {
        var filteredA = copyArray.filter((x) => x.parent !== id);

        setChildrenItems(filteredA);
      } else {
        copyArray[objIndex].price = (
          parseFloat(copyArray[objIndex].price) - parseFloat(price)
        )
          .toString()
          .match(regex3)[0];
        copyArray[objIndex].quantity = copyArray[objIndex].quantity - 1;
        setChildrenItems(copyArray);
      }
    }
  };

  const renderReviews = () => {
    if (reviews.data) {
      const current_page = reviews.current_page;
      const per_page = reviews.per_page;
      const total = reviews.total;

      const rs = reviews.data;

      return (
        <React.Fragment>
          
          <div className="menu-content-header px-3">
            <h5 className="title text-default-color font-weight-bold">
              Reviews
            </h5>
          </div>
          <div className="menu-content">
            {rs.map((rv, arrindex) => (
              <div className="row" key={arrindex}>
                <div className="col-sm-8">
                  <h5 className="title ">{rv.title}</h5>
                </div>
                <div className="col-sm-4">{rv.date}</div>

                <div className="col-sm-12">
                  <img src={rv.rating_stars[0]} alt="star"></img>{" "}
                  <img src={rv.rating_stars[1]} alt="star"></img>{" "}
                  <img src={rv.rating_stars[2]} alt="star"></img>{" "}
                  <img src={rv.rating_stars[3]} alt="star"></img>{" "}
                  <img src={rv.rating_stars[4]} alt="star"></img>
                </div>

                <div className="col-sm-12">
                  {rv.desc}
                  <hr />
                </div>
              </div>
            ))}
          </div>
          <div className="mt-3">
            <Pagination
              activePage={current_page}
              itemsCountPerPage={per_page}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              onChange={(pageNumber) => loadReviews(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First"
              lastPageText="Last"
            />
          </div>
        </React.Fragment>
      );
    }
  };

  const renderSearchedItems = () => {
    if (sitems.menu) {
      const rs = sitems.menu;

      return (
        <React.Fragment>
          <div className="menu-content-header">
            <h5 className="title text-default-color font-weight-bold">
              {items.cat.title} Dishes
            </h5>
          </div>
          <div className="menu-content">
            {rs.map((item, index) => (
              <div className="o2e-row mb-2 d-flex justify-content-around align-items-center add-border" key={index}>
                <div className="o2emenu-name p-2 p-sm-3">
                  <h5 className="title font-weight-bold">{item.title}</h5>
                </div>
                <div className="o2emenu-price text-left p-2 p-sm-3">
                  <span className="price font-weight-bold">{item.price}</span>
                </div>
                <div className="o2emenu-addcart text-center p-2 p-sm-3 d-md-flex">
                  <button
                    type="button"
                    className="o2emenucart-btn btn btn-warning ml-auto mr-md-3"
                  ></button>
                </div>
              </div>
            ))}
          </div>
        </React.Fragment>
      );
    }
  };

  const renderItems = () => {
    if (items.menu) {
      const rs = items.menu;

      return (
        <React.Fragment>
          <div className="menu-content-header">
            <h5 className="title text-default-color font-weight-bold">
              {items.cat.title} Dishes
            </h5>
          </div>
          <div className="menu-content">
            {rs.map((item, i) => {
              var ms = item.mealSizes;

              

              if (ms.length > 0) {
                return (
                  <div key={i}>
                    <div>
                      
                      <div className="o2e-row mb-2 d-flex space-between align-items-center add-border">

<div className="o2emenu-name p-2 p-sm-3">
<h5 className="title font-weight-bold">{item.title}</h5>
</div>
                      <div className="flex-1">
                        {ms.map((msr, index, elements) => {
                          var elementsArray = elements[index+1]
                          
                          
                          return (
                            <>
                      
<div className="d-flex space-between">
                <div className="o2emenu-price d-flex  text-left flex-1 pr-0">
                  <span className="price font-weight-bold">{msr.title} £{msr.price}</span>
                </div>

                {msr.js_id  ? <div className="o2emenu-addcart d-flex flex-1 text-center p-2 p-sm-3 d-md-flex">
                  <button
                    type="button"
                    className={
                      loader[msr.js_id]
                      ? "o2emenucart-btn-loader btn btn-warning ml-auto mr-md-3"
                      : item.options ? "o2emenucart-btn btn btn-warning ml-auto mr-md-3" : "o2emenucart-btn-black btn btn-warning ml-auto mr-md-3"
                  }
                    id={msr.js_id}
                    onClick={(e) =>
                      addToCart(
                        e,
                        msr.js_id,
                        item.id,
                        msr.price
                      )
                    }
                  ></button>
                </div> : ''}
                
                </div>
                            
                            </>
                          );
                        })}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="o2e-row mb-2 d-flex justify-content-around align-items-center add-border">
                    <div className="o2emenu-name p-2 p-sm-3">
                      <h5 className="title font-weight-bold fo-1">{item.title}</h5>
                    </div>
                    <div className="o2emenu-price text-left p-2 p-sm-3">
                      <span className="price font-weight-bold">
                        {item.price}
                      </span>
                    </div>
                    <div className="o2emenu-addcart text-right p-2 p-sm-3 d-md-flex">
                      <button
                      disabled = {loader[item.js_id] ? true : false}
                        type="button"
                        className={
                          loader[item.js_id]
                            ? "o2emenucart-btn-loader btn btn-warning ml-auto mr-md-3"
                            : item.options ? "o2emenucart-btn btn btn-warning ml-auto mr-md-3" : "o2emenucart-btn-black btn btn-warning ml-auto mr-md-3"
                        }
                        id={item.js_id}
                        onClick={(e) =>
                          addToCart(e, item.js_id, item.id, item.price)
                        }
                      ></button>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </React.Fragment>
      );
    }
  };

  const renderTiming = () => {
    if (takeaway.timings) {
      const rs = takeaway.timings;

      return (
        <React.Fragment>
          <div className="table-responsive">
            <table className="table table-center">
              <thead>
                <tr>
                  <th scope="col" width="150">
                    &nbsp;
                  </th>
                  <th scope="col">Delivery</th>
                  <th scope="col">Collection</th>
                </tr>
              </thead>
              <tbody>
                {rs.map((time, y) => (
                  <tr key={y}>
                    <td>{time.day}</td>
                    <td>
                      {time.open} - {time.close}
                    </td>
                    <td>
                      {time.open} - {time.close}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      );
    }
  };

  const renderCartArea = () => {
    if (cart.items) {
      const rs = cart.items;

      const rsSum = rs.reduce((accumulator, object) => {
        return accumulator + object.qty;
      }, 0);
      
      

      localStorage.setItem('total_qty', rsSum)

      if (deliveryData && cart) {
        var re2 = new RegExp("^-?\\d+(?:.\\d{0," + (2 || -1) + "})?");
        var finalItemPriceCalculation =
          parseFloat(cart.subtotal) -
          parseFloat(deliveryData.discount) +
          parseFloat(deliveryData.delivery_charges);

          
        var finalItemPrice = finalItemPriceCalculation.toFixed(2);
        var subtotalAmount = cart.subtotal

        // 
        // setFinalItemPrice(finalItemPrice)
      }
      return (
        <React.Fragment>
          <div className="o2ecart-item-body p-2">
            {rs.map((item, index) => (
              <div className="o2ecart-item d-flex align-items-center mb-1" key={index}>
                <div className="o2eitem-remove p-1">
                  <button
                    onClick={(e) => updateCart(e, index)}
                    type="button"
                    className="btn btn-block btn-o2eremove-item p-0"
                  >
                    <img
                      className="gif-container"
                      id={item.key}
                      src={
                        removeLoader[index]
                          ? LoadingGif
                          : "/images/close-icon.png"
                      }
                      alt="loading"
                    />
                  </button>
                </div>
                <div className="o2ecart-item-name p-1 flex-fill">
                  {item.qty} x {ReactHtmlParser(item.title)}
                </div>
                <div className="o2ecart-item-price p-1">
                  £{item.total_price}
                </div>
              </div>
            ))}
            <hr className="mx-3" />

            {finalItemPrice !== '0.00' ? <><div className="o2e-cart-total px-2">
              <div className="o2e-cartrow d-flex mb-2">
                <span className="o2e-cart-totaltext subtotal">Sub-Total</span>
                <span className="o2e-cart-totaltext totalprice ml-auto">
                  £{cart.subtotal}
                </span>
              </div>
              <div className="o2e-cartrow d-flex mb-2">
                <span className="o2e-cart-totaltext subtotal">Discount:</span>
                <span className="o2e-cart-totaltext discountprice ml-auto">
                  {deliveryData ? "£" + deliveryData.discount : "£0.0"}
                </span>
              </div>
              <div className="o2e-cartrow d-flex mb-2">
                <span className="o2e-cart-totaltext subtotal">
                  Delivery Fee:
                </span>
                <span className="o2e-cart-totaltext discountprice ml-auto">
                  {deliveryData ? "£" + deliveryData.delivery_charges : "£0.0"}
                </span>
              </div>
            </div>
            
            <div className="o2e-cart-mastertotal p-2">
              <div className="o2e-cartrow d-flex">
                <span className="o2e-cart-totaltext subtotal">TOTAL</span>
                <span className="o2e-cart-totaltext discountprice ml-auto">
                  £{finalItemPrice}
                </span>
              </div>
            </div></>: <div class="nce-cart-price-summary" style={{textAlign:'center'}}>
              <p>There is no food  in your cart</p>
      
			</div>}
            
            <div className="o2e-cart-footer d-flex flex-column mt-3">
              <div className="o2e-cart-footerbtns d-flex">
                <button
                disabled = {radioselected === 1 && (parseFloat(subtotalAmount) > parseFloat(takeaway.min_order_delivery) ) ? false 
                  : radioselected === 2 && (parseFloat(subtotalAmount) > parseFloat(takeaway.min_order_collection) ) ? false : true }
                
                  className="btn btn-danger o2e-placeorderbtn border-0 btn-bg text-white btn-block mr-1 mt-0 p-2 p-md-3 fo-14"
                  type="button"
                  id="o2e-placeorder-btn"
                  onClick={() => placeOrder()}
                >
                  Place Order
                </button>
                <button
                  className="btn btn-danger o2e-clearcartbtn border-0 btn-bg text-white btn-block ml-1 mt-0 p-2 p-md-3 fo-14"
                  type="button"
                  id="o2e-clearcart-btn"
                  onClick={clearCart}
                >
                  Clear Cart
                </button>

                {ls.get('user_id') === '' || ls.get('user_id') === null ? 
                <button
                className="btn btn-danger o2e-clearcartbtn border-0 btn-bg text-white btn-block ml-1 mt-0 p-2 p-md-3 button-desktop"
                type="button"
                onClick={() => redirectLogin()}>
                  Login/Register
                </button> : ''}
              </div>
              <div class="nce-cart-box-area cat_d to_hide_item allergy-margin-top" >
            <h4 class="nce-cart-head" >Allergy requirement?</h4>
 
   
    <div class="col-md-12 " >
     <div class="txt_available_offer fo-14" ><p>{takeaway.available_offer_text}</p></div>
      <p > <img src={`https://www.justorder2eat.co.uk/uploads/images/b/${takeaway.available_offer_img}`} /></p>
    </div>
  </div>
            </div>
          </div>

{cartHeader || localStorage.getItem('addmoreitem') === 'true'? 
          <div id="nec-short-cart-box" style={{top: '0px'}}>
							<div class="container">
								<div class="row">
									<div class="col-md-12">
										<div class="nec-short-cart-box-area">
                                        
											<a class="nec-short-cart" ><span class="fa fa-cart-arrow-down"></span>{rsSum}</a>
											<span class="nec-short-price">
											£{finalItemPrice}</span>
																						<a href="javascript:void(0)" id="link_place_order"   onClick={() => placeOrder()} className = {radioselected === 1 && (parseFloat(subtotalAmount) > parseFloat(takeaway.min_order_delivery) ) ? "nec-short-place-order-btn "
                  : radioselected === 2 && (parseFloat(subtotalAmount) > parseFloat(takeaway.min_order_collection) ) ? "nec-short-place-order-btn " : "nec-short-place-order-btn red-btn-disable" }
                >
											Place Order</a> 
                                            											<a href="javascript:void(0)" class="nec-short-place-clear-btn" onClick={clearCart}>Clear Cart</a>
										</div>
									</div>
								</div>
							</div>	
						</div>
    : ''}
        </React.Fragment>
      );
    }
  };

  useEffect(() => {
    
    if(categories && Array.isArray(categories) && categories.length > 0){
      
    
    var activeId = "catId_" + categories[0].id
    setCatActiveId(activeId)
    }
  },[categories])

  const renderCats = () => {
    if (categories) {
      const rs = categories;

      return (
        <React.Fragment>

          

          <div
            className={drop ? "flex-column nav-pills vertical-nav-pills d-md-block padding-top" : "flex-column nav-pills vertical-nav-pills collapse d-md-block padding-top"}
            id={activeCat ?    'hide-menu' : "v-pills-tab"}
            role="tablist"
            aria-orientation="vertical"
          >
            {rs.map((cat, index) => {
              var cat_id = "catId_" + cat.id;

              if (index === 0) {
                if (selectedCat === "1") {
                  setCatActiveId(cat_id);
                }

                return (
                  <div
                    className={catactiveId === cat_id ? "nav-link active-cat fo-14" : "nav-link fo-14"}
                    id={cat_id}
                    onClick={filterCat}
                    key={index}

                  >
                    {cat.title}
                  </div>
                );
              } else {
                return (
                  <div className={catactiveId === cat_id ? "nav-link active-cat fo-14" : "nav-link fo-14"} id={cat_id} onClick={filterCat} >
                    {cat.title}
                  </div>
                );
              }
            })}
          </div>
        </React.Fragment>
      );
    }
  };


  const renderCats2 = () => {
    if (categories) {
      const rs = categories;

      return (
        <React.Fragment>

          <div
            className={side ? "flex-column nav-pills vertical-nav-pills d-md-block padding-top" : "flex-column nav-pills vertical-nav-pills collapse d-md-block padding-top"}
            id={activeCat ?    'hide-menu' : "v-pills-tab"}
            role="tablist"
            aria-orientation="vertical"
          >
            {rs.map((cat, index) => {
              var cat_id = "catId_" + cat.id;

              if (index === 0) {
                if (selectedCat === "1") {
                  selectedCat = cat_id;
                }

                return (
                  <div
                    className="nav-link active"
                    id={cat_id}
                    onClick={filterCat}
                    key={index}
                  >
                    {cat.title}
                  </div>
                );
              } else {
                return (
                  <div className="nav-link" id={cat_id} onClick={filterCat}>
                    {cat.title}
                  </div>
                );
              }
            })}
          </div>
        </React.Fragment>
      );
    }
  };


  return (
    <>
    <Header takeaway={true} cartHeader = {cartHeader}  />

    <div class={side ? "col-md-auto o2e-mobile-tabsmenu d-md-none show" : "col-md-auto d-md-none show"}>
              {renderCats2()}
                    <button type="button" class={side ? "btn o2e-closemenu-btn btn-mobileview d-md-none p-0 custom-cat-btn" : "btn o2e-closemenu-btn btn-mobileview d-md-none hide p-0 custom-cat-btn"} onClick={() => setSideBar(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                        </svg>
                    </button>
                </div>


      <div>
        {/* <SnackBar
          open={minOrder}
          message={`Min Order is £${takeaway.min_order}`}
          position="top-right"
          type="error"
          yesLabel="Ok"
          onYes={() => setMinOrder(false)}
          closeOnClick={false}
        /> */}
      </div>

      <div>
        <SnackBar
          open={closeResturantSnackbar}
          message={takeaway ? takeaway.are_we_open ? takeaway.are_we_open.close_message : '' : ''}
          position="top-right"
          type="error"
          yesLabel="Ok"
          containerStyle={{right:'5px', paddingTop:'5px' , paddingBottom: '5px', height:'auto'}}
          onYes={() => setcloseResturantSnackbar(false)}
          closeOnClick={false}
          timeout={3000}
        />
      </div>

      <div>
        <SnackBar
          open={openSnackbar}
          message={"Item added to cart"}
          position="top-right"
          type="success"
          yesLabel="Ok"
          onYes={() => setOpenSnackbar(false)}
          closeOnClick={false}
          timeout={3000}
        />
      </div>

      <div>
        <SnackBar
          open={removeItemSnackbar}
          message={"Item removed from cart"}
          position="top-right"
          type="error"
          yesLabel="Ok"
          onYes={() => setRemoveItemSnackbar(false)}
          closeOnClick={false}
          timeout={3000}
        />
      </div>
      <Modal open={open} onClose={onCloseModal} center>
        <div className="modal-container">
          {modalData
            ? modalData.map((x , i) => {
              
                return (
                  <div key={i}>
                    <h6>{x.title}</h6>
                    {x.childs.map((y, index) => {
                      return (
                        <div key={index}>
                          <div
                            className="modal-inner-section"
                            onClick={handleClick(
                              y.id,
                              x.id,
                              y.price,
                              x.item_id,
                              y.children ? true : false
                            )}
                          >
                            <p className="modal-title">
                              {y.title} - <span>£{y.price}</span>
                            </p>
                            {selected[y.id] ? <img src={checkImage} alt="checked"/> : null}
                          </div>
                          {selectedChildren[y.id] ? (
                            <div className="modal-inner-childern-section">
                              {selectedChildren[y.id]
                                ? y.children
                                  ? y.children.map((z, ind) => {
                                      return (
                                        <div key={ind}>
                                          <div className="main-child-cont">
                                            <p className="modal-title">
                                              {z.title} -{" "}
                                              <span>£{z.price}</span>
                                            </p>
                                            <div>
                                              {childrenItems.length > 0
                                                ? childrenItems
                                                    .filter(
                                                      (x) => x.parent === z.id
                                                    )
                                                    .map((i, index) => {
                                                      return (
                                                        <div key={index}>
                                                          <span>
                                                            {i.quantity} x £
                                                            {i.price}
                                                          </span>
                                                        </div>
                                                      );
                                                    })
                                                : ""}
                                            </div>
                                            <div className="main-child-btn">
                                              <button
                                                onClick={() =>
                                                  addChildrenQuantity(
                                                    z.id,
                                                    z.price,
                                                    z.parent
                                                  )
                                                }
                                                className="o2emenucart-btn btn btn-warning ml-auto mr-md-3 btn-mar"
                                              ></button>
                                              <button
                                                onClick={() =>
                                                  removeChildrenQuantity(
                                                    z.id,
                                                    z.price,
                                                    z.parent
                                                  )
                                                }
                                                className="o2emenucart-btn btn btn-warning ml-auto mr-md-3 background-img"
                                              ></button>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : ""
                                : ""}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })
            : "Error"}
          <div className="add-to-cart-cont">
            <button
              disabled={popupItems.length > 0 ? false : true}
              onClick={() => addPopUpItems()}
            >
              Add to Cart
            </button>
            <p>Total:£{totalItemAmount}</p>
          </div>
        </div>
      </Modal>

      <Modal open={deliveryModal} onClose={onCloseDeliveryModal} center>
        <div className="delivery-modal-container">
          <h3>Sorry Delivery Late</h3>
          <div className="delivery-cont">
            <p>Delivery Starting at:</p>
            <span>
              0
              {takeaway.collection_time_from
                ? takeaway.collection_time_from.split("-")[0]
                : null}
              :00
            </span>
          </div>
          <div className="button-container">
            <button
              className="btn btn-warning btn-otelg"
              onClick={() => handleOptionItems("Pre-Order")}
            >
              Pre-Order
            </button>
            <p>OR</p>
            <button
              className="btn btn-danger btn-otelg"
              onClick={() => handleOptionItems("Collection")}
            >
              Collection
            </button>
          </div>
        </div>
      </Modal>
      <div className="container-fluid p-0">
        <div className="section inner-page-header d-md-block d-none"></div>
        <div className="section py-0 d-md-block d-none">
          <div className="container-fluid">
            <div className="o2e-user-profile d-md-flex">
              <div className="o2e-avatar text-md-left">
                <img
                  className="avatar-img"
                  alt="takeaway"
                  src={takeaway.image_url}
                />
              </div>
              <div className="o2e-profile-info align-self-end mb-2">
                <div className="o2e-rating d-flex align-items-center">
                  <img className="mr-1" alt="" src="/images/star-icon.png" />
                  <span className="o2e-rating-txt">
                    <strong>{takeaway.rating}</strong>/5(5)
                  </span>
                </div>
                <div className="o2e-user-location">
                  <p className="m-0">
                    {takeaway.address},{takeaway.town},{takeaway.postcode}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section o2e-tabsmenu-section" style={cartHeader ? {marginTop:'44px'} : {paddingTop:'1rem'}}>
          <div className="container-fluid">
            <div className="o2e-menu-tabs rounded">
              <ul
                className="nav nav-pills nav-justified1"
                id="menu-pills-tab"
                role="tablist"
              >
                <li className="nav-item  flex-fill fo-16" role="presentation">
                  <div
                    className="nav-link active"
                    id="pills-menu"
                    onClick={showTab}
                  >
                    Menu
                  </div>
                </li>
                <li className="nav-item  flex-fill fo-16" role="presentation">
                  <div
                    className="nav-link"
                    id="pills-search"
                    onClick={showTab}
                  >
                    Search Menu
                  </div>
                </li>
                <li className="nav-item  flex-fill fo-16" role="presentation">
                  <div
                    className="nav-link"
                    id="pills-special-offer"
                    onClick={showTab}
                  >
                    Special Offer
                  </div>
                </li>
                <li className="nav-item  flex-fill fo-16" role="presentation">
                  <div
                    className="nav-link"
                    id="pills-takewatinfo"
                    onClick={showTab}
                  >
                    Takeway Information
                  </div>
                </li>
                <li className="nav-item  flex-fill fo-16" role="presentation">
                  <div
                    className="nav-link"
                    id="pills-noticeboard"
                    onClick={showTab}
                  >
                    Notice Board
                  </div>
                </li>
                <li className="nav-item  flex-fill fo-16" role="presentation">
                  <div
                    className="nav-link"
                    id="pills-ohrating"
                    onClick={showTab}
                  >
                    Our Hygine Rating
                  </div>
                </li>
                <li className="nav-item  flex-fill fo-16" role="presentation">
                  <div
                    className="nav-link"
                    id="pills-reviews"
                    onClick={showTab}
                  >
                    Reviews
                  </div>
                </li>
                {takeaway.book_table ? 
                <li className="nav-item  flex-fill fo-16" role="presentation">
                  <div
                    className="nav-link"
                    id="pills-bookatable"
                    onClick={showTab}
                  >
                    Book a Table
                  </div>
                </li> : ''}

{ls.get('user_id') === '' || ls.get('user_id') === null ? 
                <li className="nav-item flex-fill button-desktop" role="presentation">
                  <div
                    className="nav-link nav-register"
                    id="pills-bookatable"
                  >
                                      <Link to="/login">login/Register</Link> 
                  </div>

                  
                </li> : ''} 
              </ul>
            </div>
          </div>
        </div>
        <div className="section pt-0 pb-4 pb-md-5 position-relative">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-auto">
                <div className="o2e-vertical-tabsmenu">
                  <div className="o2e-tabsmenu-title p-0 mb-0 d-flex justify-content-between align-items-center">
                    <h5 className="font-weight-bold text-warning m-0 mb-md-1 d-block p-md-2">
                      Categories
                    </h5>
                    <button
                      type="button"
                      className="btn o2e-closemenu-btn d-md-none p-0"
                      data-toggle="collapse"
                      data-target="#v-pills-tab"
                      aria-expanded="false"
                      aria-controls="v-pills-tab"
                      onClick={() => openDropdown()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#ffc713"
                        className="bi bi-list"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                        />
                      </svg>
                    </button>
                  </div>

                  {renderCats()}
                </div>
              </div>



              <div className="col-md mb-4 mb-lg-0 mt-4">
                <div ref={myRef} className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-menu-div"
                    role="tabpanel"
                    aria-labelledby="pills-menu-tab"
                  >
                    {renderItems()}
                  </div>
                  <div className="tab-pane " id="pills-search-div">
                    <div className="o2e-searchmenu-wrapper mb-3 mb-md-4">
                      <form className="search-form">
                        <div className="form-group mb-0 position-relative">
                          <input
                            className="form-control form-control-lg rounded-pill o2e-search-input"
                            type="text"
                            placeholder="Search for a dish or restaurant"
                            onChange={searchChanged}
                          />
                          <button
                            className="btn btn-danger border-0 rounded-pill btn-bg btn-lg search-btn"
                            type="button"
                            id="o2e-search-btn"
                          >
                            Search
                          </button>
                        </div>
                      </form>
                    </div>

                    {renderSearchedItems()}
                  </div>
                  <div className="tab-pane " id="pills-special-offer-div">
                    <div className="card o2e-offer-card">
                      <div className="card-header bg-warning text-uppercase text-center">
                        <h5 className="m-0">Special Offer</h5>
                      </div>
                      <div className="card-body">
                        {takeaway.special_offer_text}
                      </div>
                    </div>

                    
                  </div>
                  <div className="tab-pane" id="pills-takewatinfo-div">
                    <div className="card takeway-info-card mb-4 position-relative">
                      
                      <div class="o2e-specialoffer">{takeaway.promotional_discount}</div>
                      <div className="card-content">
                        <div className="row">
                          <div className="col-md">
                            <div className="card-header bg-transparent border-0 p-0 my-4">
                              <h5>{takeaway.title}</h5>
                            </div>
                            <div className="card-body p-0">
                              <div className="row nce-hidden-mobile">
                                <div className="col-lg-5 col-md-12 col-sm-12 mb-3">
                                  <ul className="nav nce-search-single-nav flex-column">
                                    <li>
                                      <div className="nce-img-icon">
                                        <img
                                          src="/images/map-icon.png"
                                          alt="img"
                                        />
                                      </div>
                                      {takeaway.address},{takeaway.town},
                                      {takeaway.postcode}
                                    </li>
                                    <li>
                                      <div className="nce-img-icon">
                                        <img
                                          src="/images/umbrella-icon.png"
                                          alt="img"
                                        />
                                      </div>
                                      {takeaway.second_name}
                                    </li>
                                    <li>
                                      <div className="nce-img-icon">
                                        <img
                                          src="/images/mobile-icon.png"
                                          alt="img"
                                        />
                                      </div>
                                      {takeaway.phone}
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-lg-3 col-md-12 col-sm-12 mb-3">
                                  <ul className="nav nce-search-single-nav flex-column">
                                    <li>
                                      <div className="nce-img-icon">
                                        <img
                                          src="/images/clock-icon-2.png"
                                          alt="img"
                                        />
                                      </div>
                                      {takeaway.delivery_time_from}
                                    </li>
                                    <li>
                                      <div className="nce-img-icon">
                                        <img
                                          src="/images/cart-icon.png"
                                          alt="img"
                                        />
                                      </div>
                                      Min Delivery:{" "}
                                      <span>£{takeaway.min_order}</span>
                                    </li>
                                    <li>
                                      <div className="nce-img-icon">
                                        <img
                                          src="/images/delivery-icon.png"
                                          alt="img"
                                        />
                                      </div>
                                      Delivery: <span>{takeaway.delivery}</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                                  <ul className="nav nce-search-single-nav flex-column">
                                    <li>
                                      <div className="nce-img-icon">
                                        <img
                                          src="/images/love-icon.png"
                                          alt="img"
                                        />
                                      </div>
                                      Feed a belly opted in:{" "}
                                      <span>{takeaway.feed_belly}</span>
                                    </li>
                                    <li>
                                      <div className="nce-img-icon">
                                        <img
                                          src="/images/select-icon.png"
                                          alt="img"
                                        />
                                      </div>
                                      Food Hygiene Rating:{" "}
                                      <span>
                                        {takeaway.food_hygiene_rating}{" "}
                                      </span>
                                    </li>
                                    <li>
                                      <div className="nce-img-icon">
                                        <img
                                          src="/images/clock-icon-2.png"
                                          alt="img"
                                        />
                                      </div>
                                      Collection {takeaway.delivery_time_from}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card o2e-littleinfo border-0 p-0">
                      <div className="card-content">
                        <div className="card-header bg-transparent border-0 p-0 mb-3">
                          <h5 className="m-0">A little bit about us</h5>
                        </div>
                        <div className="card-body card-seprator pt-0 px-0 mb-4">
                          <p>
                            {takeaway.about_us}
                          </p>
                          
                        </div>
                        <div className="card-header bg-transparent border-0 p-0 mb-3">
                          <h5 className="m-0">Openning times</h5>
                        </div>
                        <div className="card-body pt-0 px-0 mb-4">
                          {renderTiming()}
                        </div>
                        <div className="card-header bg-transparent border-0 p-0 mb-3">
                          <h5 className="m-0">Where to find us</h5>
                        </div>
                        <div className="card-body pt-0 px-0 mb-4">
                          <iframe
                          title="map"
                            src={
                              "https://maps.google.com/maps?q=" +
                              takeaway.google_address +
                              "+()&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                            }
                            width="100%"
                            height="350"
                            allowFullScreen=""
                            loading="lazy"
                            style={{ border: 0 }}
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane " id="pills-noticeboard-div">
                    <div className="card o2e-offer-card">
                      <div className="card-header bg-warning text-uppercase text-center">
                        <h5 className="m-0">NOTICE BOARD</h5>
                      </div>
                      <div className="card-body">
                        {takeaway.available_offer_text}
                      </div>
                    </div>
                  </div>


                  <div className="tab-pane " id="pills-ohrating-div">
                    <div className="card o2e-offer-card">
                      <div className="card-header bg-warning text-uppercase text-center">
                        <h5 className="m-0">OUR HYGINE RATING</h5>
                      </div>
                      <div className="card-body text-center">
                        {takeaway.food_hygiene_rating}
                      </div>
                    </div>
                  </div>
                  {/* <div className="tab-pane " id="pills-ohrating-div">
                    Our Hygine Rating
                  </div> */}
                  <div className="tab-pane " id="pills-reviews-div">
                    {renderReviews()}
                  </div>
                  <div className="tab-pane " id="pills-bookatable-div">
                    <a href={takeaway.book_table} target='_blank'>{takeaway.book_table}</a>
                  </div>
                </div>
              </div>
              
              <div className="col-lg-auto sidebar mt-md-4 mb-lg-0">
                <div  className="sidebar-content">
                  <div className="o2e-card-header widget-bg overflow-hidden">
                    <div className="o2e-widget-title bg-red text-center p-3">
                      <h6 className="m-0">Your Order</h6>
                    </div>
                    <div id="pt_code" className="o2e-discount-text bg-yellow text-center p-2 fo-7 fo-14">
                      {takeaway.discount_text}
                    </div>
                  </div>

{postCodeVal? 
                 <div className="o2e-changepasscode small text-center p-2 my-2">
                 {takeaway.is_delivery_charges === 1 ?
                 <>
                    {postcodeInput ? (
                      <>
                      <div style={{display: 'flex', alignItems:'center'}}>
                        My Postcode Area :{" "}
                        <input
                          type="text"
                          value={postCodeValue}
                          maxLength='4'
                          onChange={(e) => onPostCodeChange(e)}
                        />
                        <button onClick={() => changePostCode()}>GO</button>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        My Postcode Area{" "}
                        {postCodeValue ? postCodeValue : "mk40"}{" "}
                        <a className="link" onClick={() => showInput()}>
                          Change Postcode
                        </a>{" "}
                      </>
                    )}

                    {takewayErrorMessage ? (
                      <p  className="takeaway-error"> {takewayErrorMessage} </p>
                    ) : (
                      ""
                    )}
                    </>: ''}
                  </div> : ''}
                  <div className="o2e-cart-content">
                    <div className="o2e-deliveryoprion p-1 rounded-pill mb-2">
                      <div
                        className="btn-group btn-group-toggle d-flex align-items-center"
                        data-toggle="buttons"
                      >
                        <label
                          className={
                            radioselected === 1
                              ? "btn o2e-togglebtn rounded-pill active"
                              : "btn o2e-togglebtn rounded-pill"
                          }
                        >
                          <input
                            type="radio"
                            name="option"
                            checked={radioselected === 1}
                            value={inputValue1}
                            onChange={onChange1}
                          />{" "}
                          Delivery 40-60 mins<small className="d-block"></small>
                        </label>
                        <label
                          className={
                            radioselected === 2
                              ? "btn o2e-togglebtn rounded-pill active"
                              : "btn o2e-togglebtn rounded-pill"
                          }
                        >
                          <input
                            type="radio"
                            name="option"
                            checked={radioselected === 2}
                            value={inputValue2}
                            onChange={onChange2}
                          />{" "}
                          Collection 30-45 mins
                          <small className="d-block"></small>
                        </label>
                      </div>
                    </div>

                    {renderCartArea()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="button" className="btn d-md-none o2e-show-categories" onClick={() => openSideBar()}>
            View categories
          </button>
        </div>
      </div>
    </>
  );
};

export default Menu;
