import React, { useState } from "react";
import { Link } from "react-router-dom";
import SnackBar from "my-react-snackbar";
import Header from '../layouts/Header'
const initialValues = {
  Email: "",
};

const authAxios = global.authAxios;

export default function ForgotPassword() {
  const [values, setValues] = useState(initialValues);
  const [loader, setLoader] = useState(false)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState(false)
  const [type, setType] = useState("error")


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const forgotPassword = async (e) => {
    setLoader(true)
      e.preventDefault()
    var URL = "/forgotPassword";
    var forgotPasswordData = {email: values.Email}
    await authAxios.post(URL, forgotPasswordData).then((response) => {
      if( response.data.status === "Ok" ){
        setLoader(false)
        setOpen(true)
        setMessage("New password has been sent to your email address")
        setType("success")
      }
      else{
        setLoader(false)
        setOpen(true)
        setMessage(response.data.message)
        setType("error")
      }
    });
  };

  return (
    <>
    <Header />
    <SnackBar
          open={open}
          message={message}
          position="top-right"
          type={type}
          yesLabel="Ok"
          onYes={() => setOpen(false)}
        />
      <main id="nce-main-content">
        <div className="container" id="main_container">
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-9">
                <div id="error"></div>

                <div className="col-md-8">
                  <div className="panel panel-default">
                    <div className="panel-heading nce-cart-head">Forgot Password</div>
                    <div className="panel-body">
                      <form
                        id="frm_main"
                        name="frm_main"
                        method="post"
                        novalidate="novalidate"
                      >
                        <div className="form-group">
                          {" "}
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            value={values.Email}
                            onChange={handleInputChange}
                            name="Email"
                          />
                        </div>
                        <div className="form-group ">
                          <input type="hidden" name="redirect_to" value="" />
                          {loader? <div className="col-md-12" id="loader_img">
                            <img src="https://www.justorder2eat.co.uk/public/front/assets/images/ajax-loader.gif" alt="loading-gif"/>
                          </div> : ''}
                          <div className="col-md-12 btn-style">
                            <input
                              type="submit"
                              id="btn-login"
                              value="submit"
                              className="btn btn-danger btn_ote"
                              name="btn"
                              onClick={(e) => forgotPassword(e)}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-md-12 control">
                            <div>
                              {" "}
                              Don't have an account!{" "}
                              <Link
                                to="/register"
                                className=""
                              >
                                {" "}
                                Register Here{" "}
                              </Link>{" "}
                            </div>
                          </div>
                        </div>
                      </form>{" "}
                      <div className="forget"> &nbsp; </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

