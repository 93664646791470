import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "react-responsive-modal/styles.css";
import SnackBar from "my-react-snackbar";
import LoadingGif from "../../images/loading-waiting.gif";
import ReactHtmlParser from "react-html-parser";
import FoodAllergy from "../../images/food-allergy.png";
import { useHistory } from "react-router-dom";
import SecureLS from "secure-ls";
import Header from '../layouts/Header';
import { Link } from "react-router-dom";

var ls = new SecureLS({encodingType: 'aes'});


const authAxios = global.authAxios;

const initialValues = {
  Email: "",
  Name: "",
  Mobile: "",
  Address: "",
  Comments: "",
  Password: "",
};

export default function Checkout() {
  const  user_id  = ls.get("user_id")
  let history = useHistory();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { id } = useParams();
  const [takeaway, setTakeawayData] = useState([]);
  const [cart, setCartData] = useState([]);
  const [removeLoader, setRemoveLoader] = useState({});
  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [radioselected, setRadioSelected] = useState();
  const [deliveryData, setDeliveryData] = useState();
  const [values, setValues] = useState(initialValues);
  const [selectedTime, setSelectedTime] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [orderSnackbar, setOrderSnackbar] = React.useState(false);
  const [minOrder, setMinOrder] = useState(false);
  const [timings, setTiming] = useState(false);
  const [yes, setYes] = useState(false);
  const [no, setNo] = useState(false);


  const [emailError, setEmailError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [mobileError, setMobileError] = useState(false)
  const [addressError, setAddressError] = useState(false)
  const [timingError , setTimingError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [allergyError, setAllergyError] = useState(false)
  const [selectedPayment , setSelectedPayment] = useState("")
  const [paymentError , setPaymentError] = useState(false)
  const [btnActive , setBtnActive] = useState(false)

  useEffect(() => {
    if(localStorage.getItem("user_id")){
    setInitialData();
    }
  }, []);

  const setInitialData = async () => {
    const customerData = await authAxios.get(`/loadCustomer/${user_id}`);

    setValues({
      Email: customerData.data.customer.email,
      Name: customerData.data.customer.full_name,
      Address: customerData.data.customer.address,
      Mobile: customerData.data.customer.phone,
    });
  };

  const redirectReview = () => {
    history.push(`/takeaway/${id}`);
  };

  const handleSelectChange = (event) => {
    setSelectedTime(event.target.value);
    setTimingError(false)
  };

  const handlePaymentSelectChange = (event) => {
    setSelectedPayment(event.target.value)
    setPaymentError(false)

    
  }

  const onChange1 = () => {
    if(localStorage.getItem('postcode')){
      localStorage.setItem("Delivery", "Pre-Order");
      localStorage.setItem("radiovalue", 1);
      setInputValue1(inputValue1);
      setRadioSelected(1);
    }
    else{
      history.push(`/takeaway/${id}`);
    }
  };
  const onChange2 = () => {
    localStorage.setItem("Delivery", "Collection");
    localStorage.setItem("radiovalue", 2);
    setInputValue2(inputValue2);
    setRadioSelected(2);
  };

  useEffect(() => {
    if (localStorage.getItem("radiovalue")) {
      setRadioSelected(parseInt(localStorage.getItem("radiovalue")));
    }
    loadTakeaway();
    loadCart();

  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("is_delivery_start") === "0" &&
      localStorage.getItem("radiovalue") === "1"
    ) {
      setTiming(true);
    } else {
      setTiming(false);
    }
  }, [timings, radioselected]);

  useEffect(() => {
    const deliveryData = {
      takeaway_id: id,
      is_delivery: radioselected === 1 ? 1 : 0,
      subtotal: cart.subtotal,
      payment_gateaway: "COD",
      postcode: localStorage.getItem("postcode"),
    };
    var calculateDeliveryCharges = "/calculateDeliveryCharges";
    authAxios.post(calculateDeliveryCharges, deliveryData).then((response) => {
      if (response.data.status === "success") {
        setDeliveryData(response.data);
      }
    });
    
  }, [cart, radioselected]);

  const loadTakeaway = async () => {
    const res = await authAxios.get(`/loadTakeaway/${id}`);
    setTakeawayData(res.data.takeaway);
  };

  const loadCart = async () => {
    const res = await authAxios.get(`/cartContent/${id}`);
    setCartData(res.data);
    setBtnActive(true)
  };


  useEffect(() =>{
    if(values.Mobile !== ''){
      setMobileError(false)
    }
    if(values.Name !== ''){
     setNameError(false)
    }
    if(values.Address !== ''){
 setAddressError(false)
    }

    if(checked && values.Email !== ''){
      setEmailError(false)
    }

    if(checked && values.Password !== ''){
      setPasswordError(false)
    }
  }, [values])

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });


  };

  const setYesBtnActive = () => {
    setYes(true);
    setNo(false);
    setAllergyError(false)
  };

  const setNoBtnActive = () => {
    setNo(true);
    setYes(false);
    setAllergyError(false)
  };

  const updateCart = async (e, index) => {
    setRemoveLoader((state) => ({
      ...state,
      [index]: true,
    }));
    var selectedItem = e.target.id;

    const product = { id: selectedItem, takeaway_id: id };

    var URL = "/utc";

    await authAxios.post(URL, product).then((response) => {
      if (response.data.success === 1) {
        setRemoveLoader((state) => ({
          ...state,
          [index]: false,
        }));
      }
    });
    loadCart();
  };

  const AddMoreDishes = () => {
    localStorage.setItem('addmoreitem', 'true')
  }

  const placeOrder = async () => {
    if (parseFloat(cart.subtotal) > parseFloat(takeaway.min_order)) {
      // if (
      //   values.Name === "" ||
      //   values.Mobile === "" ||
      //   values.Address === "" ||
      //   (timings && selectedTime === "") ||
      //   (checked === false && checked === true && values.Password === "")
      // ) {
      //   setOpenSnackbar(true);
      // }
      if(values.Name === '' && values.Mobile === '' && values.Address === '' && (timings && selectedTime === "")){
        setNameError(true)
        setMobileError(true)
        setAddressError(true)
        setTimingError(true)
      }
      else if(values.Name === '' && values.Mobile === ''){
        setNameError(true)
        setMobileError(true)
      }
      else if(values.Name === '' && values.Address === ''){
        setNameError(true)
        setAddressError(true)
      }
      else if(values.Name === '' && (timings && selectedTime === "")){
        setNameError(true)
        setTimingError(true)
      }
      else if(values.Mobile === '' && values.Address === ''){
        setMobileError(true)
        setAddressError(true)
      }

      else if(values.Mobile === '' && (timings && selectedTime === "")){
        setMobileError(true)
        setTimingError(true)
      }
      else if(values.Address === '' && (timings && selectedTime === "")){
        setAddressError(true)
        setTimingError(true)
      }
      else if(values.Name === ''){
        setNameError(true)
      }
      else if(values.Mobile === ''){
        setMobileError(true)
      }
      else if(values.Address === ''){
        setAddressError(true)
      }
      else if(timings && selectedTime === ""){
        setTimingError(true)
        document.getElementById('timing_type').scrollIntoView()
      }
      else if(checked && (values.Password === '' && values.Email === '')){
        setEmailError(true)
        setPasswordError(true)
      }
      else if(selectedPayment === ""){
        setPaymentError(true)
        document.getElementById('payment_type').scrollIntoView()
      }
      else if(values.Mobile === '' &&  selectedPayment === ""){
        setMobileError(true)
        setPaymentError(true)
        document.getElementById('payment_type').scrollIntoView()
      }
      else if(values.Address === '' &&  selectedPayment === ""){
        setAddressError(true)
        setPaymentError(true)
        document.getElementById('payment_type').scrollIntoView()
      }

      else if(checked && values.Email === ''){
        setEmailError(true)
      }

      else if(checked && values.Password === ''){
        setPasswordError(true)
      }
      else if(yes === false && no === false){
        setAllergyError(true)
        document.getElementById('food_allergy').scrollIntoView()
      }
      
      
      
      
      else {
        let orderData = {
          takeaway_id: id,
          cemail: values.Email,
          cname: values.Name,
          cphone: values.Mobile,
          caddress: values.Address,
          ccomments: values.Comments,
          payment_method: selectedPayment,
          is_account: checked ? 1 : 0,
          pass: values.Password,
          user_id: localStorage.getItem("user_id") ? user_id : 0,
          delivery_time: selectedTime,
          postcode: localStorage.getItem("postcode"),
          is_delivery: radioselected,
        };
        var URL = "/orderPlace";

        await authAxios.post(URL, orderData).then((response) => {
          if (response.data.message === "success") {
            authAxios.get(`/clearBasket/${id}`);
            history.push(`/success/${response.data.oid}`);
            localStorage.removeItem('total_qty')
          }
        });
      }
    } else {
      setMinOrder(true);
    }
  };

  if (cart.items) {
    var rs = cart.items;
    
    const rsSum = rs.reduce((accumulator, object) => {
      return accumulator + object.qty;
    }, 0);
    
    

    localStorage.setItem('total_qty', rsSum)
    if (deliveryData && cart) {
      var re2 = new RegExp("^-?\\d+(?:.\\d{0," + (2 || -1) + "})?");
      var finalItemPriceCalculation =
        parseFloat(cart.subtotal) -
        parseFloat(deliveryData.discount) +
        parseFloat(deliveryData.delivery_charges);

        
      var finalItemPrice = finalItemPriceCalculation.toFixed(2);
      var subtotalAmount = cart.subtotal

      // console.log(typeof(subtotalAmount))
      // setFinalItemPrice(finalItemPrice)
    }
  } else {
  }
  return (
    <>
    <Header />
      <div>
        <SnackBar
          open={minOrder}
          message={`Min Order is £${takeaway.min_order}`}
          position="top-right"
          type="error"
          yesLabel="Ok"
          onYes={() => setMinOrder(false)}
        />
      </div>

      <div>
        <SnackBar
          open={openSnackbar}
          message={"Fields labelled with * are required"}
          position="top-right"
          type="error"
          yesLabel="Ok"
          onYes={() => setOpenSnackbar(false)}
        />
      </div>

      <div>
        <SnackBar
          open={orderSnackbar}
          message={"Order has been placed"}
          position="top-right"
          type="success"
          yesLabel="Ok"
          onYes={() => setOrderSnackbar(false)}
        />
      </div>

      <section className="section py-0 position-relative">
        
        <div className="container">
          <div className="row">

            
            <div className="col-md mb-4 mb-lg-0">
            <div className="card o2e-card mb-3">
                 <div className="card-body custom-card-body">
                  <div
                    id="nce-menu-item"
                    className="nce-search-single chk_visibility nce-checkout custom-nce-checkout"
                  >
                    <div className="row">
                      <div className="col-lg-10 col-sm-12">
                        <div className="nce-search-single-left">
                          <h2> {takeaway ? takeaway.title : ""}</h2>
                          <div className="row nce-hidden-mobile">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <ul className="nce-search-single-nav">
                                <li>
                                  <div className="nce-img-icon">
                                    <img
                                      src="https://www.justorder2eat.co.uk/public/front/assets/images/map-icon.png"
                                      alt="map"
                                    />
                                  </div>
                                  {takeaway ? takeaway.address : ""}{" "}
                                </li>
                                <li>
                                  <div className="nce-img-icon">
                                    <img
                                      src="https://www.justorder2eat.co.uk/public/front/assets/images/umbrella-icon.png"
                                      alt="umbrella"
                                    />
                                  </div>

                                  <div className="custom-flex">
                                  {takeaway ? takeaway.second_name : ""}{" "}
                                  </div>
                                </li>
                                <li>
                                  <div className="nce-img-icon">
                                    <img
                                      src="https://www.justorder2eat.co.uk/public/front/assets/images/mobile-icon.png"
                                      alt="mobile"
                                    />
                                  </div>
                                  {takeaway ? takeaway.phone : ""}
                                </li>
                              </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <ul className="nce-search-single-nav">
                                <li>
                                  <div className="nce-img-icon">
                                    <img
                                      src="https://www.justorder2eat.co.uk/public/front/assets/images/clock-icon.png"
                                      alt="clock"
                                    />
                                  </div>
                                  {takeaway ? takeaway.delivery_time_from : ""}{" "}
                                </li>
                                <li>
                                  <div className="nce-img-icon">
                                    <img
                                      src="https://www.justorder2eat.co.uk/public/front/assets/images/cart-icon.png"
                                      alt="cart"
                                    />
                                  </div>
                                  <div className="custom-flex">
                                  <span>
                                  Min Delivery:{" "}
                                  </span>
                                  <span>
                                    {" "}
                                    £ {takeaway ? takeaway.min_order : ""}{" "}
                                  </span>
                                  </div>
                                </li>
                                <li>
                                  <div className="nce-img-icon">
                                    <img
                                      src="https://www.justorder2eat.co.uk/public/front/assets/images/delivery-icon.png"
                                      alt="delivery"
                                    />
                                  </div>
                                  <div className="custom-flex">
                                    <span>
                                  Delivery:{" "}
                                  </span>
                                  <span>
                                    {takeaway ? takeaway.delivery : ""}{" "}
                                  </span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <ul className="nce-search-single-nav">
                                <li>
                                  <div className="nce-img-icon">
                                    <img
                                      src="https://www.justorder2eat.co.uk/public/front/assets/images/love-icon.png"
                                      alt="love"
                                    />
                                  </div>
                                  <div className="custom-flex">
                                  <span>Feed a belly opted in: </span><span>Yes</span>
                                  </div>
                                </li>
                                <li>
                                  <div className="nce-img-icon">
                                    <img
                                      src="https://www.justorder2eat.co.uk/public/front/assets/images/select-icon.png"
                                      alt="select"
                                    />
                                  </div>
                                  <div className="custom-flex">
                                  <span>Food Hygiene Rating:{" "}</span>
                                  <span>
                                    {takeaway
                                      ? takeaway.food_hygiene_rating
                                      : ""}{" "}
                                  </span>
                                  </div>
                                </li>
                                <li>
                                  <div className="nce-img-icon">
                                    <img
                                      src="https://www.justorder2eat.co.uk/public/front/assets/images/clock-icon.png"
                                      alt="clock"
                                    />
                                  </div>
                                  {takeaway
                                    ? takeaway.collection_time_from
                                    : ""}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-2 nce-hidden-mobile">
                        <div className="nce-search-single-client-right">
                        <a href={global.frontEndUrl + 'takeaway' + '/' + takeaway.id}>
                            {" "}
                            <img
                            className="width-large"
                              src={takeaway ? takeaway.image_url : ""}
                              alt="takeaway"
                            />
                          </a>
                          <div
                            className="nce-search-single-review"
                            onClick={() => redirectReview()}
                          >
                            <a className="nce-search-review-link">
                              {" "}
                              {takeaway ? takeaway.reviews : ""} reviews
                            </a>
                            <br />

                            <img src="https://www.justorder2eat.co.uk/public/front/images/greenstar-full.png" alt="greenstart"/>
                            <img src="https://www.justorder2eat.co.uk/public/front/images/greenstar-full.png" alt="greenstart"/>
                            <img src="https://www.justorder2eat.co.uk/public/front/images/greenstar-full.png" alt="greenstart"/>
                            <img src="https://www.justorder2eat.co.uk/public/front/images/greenstar-full.png" alt="greenstart"/>
                            <img src="https://www.justorder2eat.co.uk/public/front/images/greenstar-full.png" alt="greenstart"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>


              
              <div className="card o2e-card o2efoodallergy-card mb-3 text-center p-sm-4">
                <div className="card-body" id="food_allergy">
                  <img className="mb-3" alt="Food allergy" src={FoodAllergy} />
                  <div className="btn-group d-block d-sm-none" role="group">
                    
                    <button
                      type="button"
                      className="btn btn-secondary border-0 px-5 active bg-yellow"
                      disabled={btnActive ? false : true}
                    >
                      <a class={btnActive ? '' : "disable-links"} href={global.frontEndUrl + 'takeaway' + '/' + takeaway.id} onClick={() => AddMoreDishes()}>
                      ADD MORE DISHES
                      </a>
                    </button>
                  </div>
                  <p className="card-text fo-19">
                    Do you or the person you’re ordering for have a food
                    allergy?
                  </p>
                  <div className="btn-group" role="group">
                    <button
                      type="button"
                      className={
                        no
                          ? "btn btn-secondary border-0 px-5 active"
                          : "btn btn-secondary border-0 px-5"
                      }
                      onClick={() => setNoBtnActive()}
                    >
                      No
                    </button>
                    <button
                      type="button"
                      className={
                        yes
                          ? "btn btn-secondary border-0 px-5 active"
                          : "btn btn-secondary border-0 px-5"
                      }
                      onClick={() => setYesBtnActive()}
                    >
                      Yes
                    </button>
                  </div>
                </div>
                <span className="error-msg">
                  {allergyError ? "Please select allergy" : ''}
                </span>
                <span className="error-msg">
                  {yes ? takeaway.allergy_text : ""}
                </span>
              </div>

              <div className="card o2e-card o2epayment-card mb-3 text-center p-sm-4" id="payment_type">
                <div className="card-body">
                  <h2 className="card-title mt-3 mb-3">Select Your Payment Type</h2>
                  <select className="custom-select o2e-custom-select mx-auto rounded-pill custom-select-lg mb-3" value={selectedPayment}
                      onChange={handlePaymentSelectChange}>
                    <option>Select Your Payment Type *</option>
                    {takeaway.payment_methods ? (
                      takeaway.payment_methods.map((x, index) => {
                        
                        return <option value={x.name} key={index}>{x.label}</option>;
                      })
                    ) : (
                      <option>Error</option>
                    )}
                  </select>
                  {paymentError ? <p className="error-input">Please complete this</p> : ''}
                  <img class="img-fluid" alt="payment method" src="/images/payment-method.png"></img>
                </div>
              </div>

              {timings ? (
                <div className="card o2e-card o2epayment-card mb-3 text-center p-sm-4" id="timing_type">
                  <div className="card-body">
                    <h2 className="card-title mt-3 mb-3">Select Your Timing</h2>
                    <select
                      value={selectedTime}
                      onChange={handleSelectChange}
                      className="custom-select o2e-custom-select mx-auto rounded-pill custom-select-lg mb-3"
                    >
                      <option>Select Your Timing *</option>
                      {takeaway.delivery_timings ? (
                        takeaway.delivery_timings.map((x, index) => {
                          return <option value={x} key={index}>{x}</option>;
                        })
                      ) : (
                        <option>Error</option>
                      )}
                    </select>
                    {timingError ? <p className="error-input">Please complete this</p> : ''}
                  </div>
                </div>
              ) : (
                ""
              )}

<div class="card o2e-card securepayment-card mb-3 text-center d-md-flex d-none" >
                        <div class="card-body mx-auto" >
                            <div class="d-flex justify-content-center align-items-end" >
                                <img class="mr-3" alt="secrure payment" src="/images/secure-payment.png" />
                                <h3 class="card-title m-0" >Your Payment is secure.</h3>
                            </div>
                            <p class="card-text d-block clearfix" >We take cyber security very seriously! Our payment Processing is secured with a 2048-bit SSL encrypted System.</p>
                        </div>
                    </div>
              
              
            <div className="card o2e-card mb-3">
            <div class="card-body" >
                            <h3 class="card-title text-center text-md-left">Special Offer</h3>
                            <p class="card-text dark" >{takeaway ? takeaway.special_offer_text : ""} </p>
                        </div>
                        </div>
            </div>
            <div className="col-lg-auto sidebar">
              <div className="sidebar-content">
                <div className="o2e-card-header widget-bg overflow-hidden">
                  <div className="o2e-widget-title bg-red text-center p-3">
                    <h6 className="m-0 fo-18">Your Order</h6>
                  </div>
                  <div className="o2e-discount-text bg-yellow text-center p-2 fo-14">
                    {takeaway.discount_text}
                  </div>
                </div>
                {
                  <div className="o2e-cart-content margin-form pt-0">
                  <div className="o2e-deliveryoprion p-1 rounded-pill mb-2">
                    <div
                      className="btn-group btn-group-toggle d-flex align-items-center"
                      data-toggle="buttons"
                    >
                      <label
                        className={
                          radioselected === 1
                            ? "btn o2e-togglebtn rounded-pill pl active"
                            : "btn o2e-togglebtn rounded-pill pl"
                        }
                      >
                        <input
                          type="radio"
                          name="option"
                          checked={radioselected === 1}
                          value={inputValue1}
                          onChange={onChange1}
                        />{" "}
                        Delivery 40-60 mins<small className="d-block"></small>
                      </label>
                      <label
                        className={
                          radioselected === 2
                            ? "btn o2e-togglebtn rounded-pill pl active"
                            : "btn o2e-togglebtn rounded-pill pl"
                        }
                      >
                        <input
                          type="radio"
                          name="option"
                          checked={radioselected === 2}
                          value={inputValue2}
                          onChange={onChange2}
                        />{" "}
                        Collection 30-45 mins
                        <small className="d-block"></small>
                      </label>
                    </div>
                  </div>
                  <div className="o2ecart-item-body p-2">
                    {rs
                      ? rs.map((item, index) => (
                          <div className="o2ecart-item d-flex align-items-center mb-1" key={index}>
                            <div className="o2eitem-remove p-1">
                              <button
                                onClick={(e) => updateCart(e, index)}
                                type="button"
                                className="btn btn-block btn-o2eremove-item p-0"
                              >
                                <img
                                  className="gif-container"
                                  id={item.key}
                                  src={
                                    removeLoader[index]
                                      ? LoadingGif
                                      : "/images/close-icon.png"
                                  }
                                  alt="loading"
                                />
                              </button>
                            </div>
                            <div className="o2ecart-item-name p-1 flex-fill">
                              {item.qty} x {ReactHtmlParser(item.title)}
                            </div>
                            <div className="o2ecart-item-price p-1">
                              £{item.total_price}
                            </div>
                          </div>
                        ))
                      : ""}
                    <hr className="mx-3" />
                    <div className="o2e-cart-total px-2">
                      <div className="o2e-cartrow d-flex mb-2">
                        <span className="o2e-cart-totaltext subtotal">
                          Sub-Total
                        </span>
                        <span className="o2e-cart-totaltext totalprice ml-auto">
                          £{cart.subtotal}
                        </span>
                      </div>
                      <div className="o2e-cartrow d-flex mb-2">
                        <span className="o2e-cart-totaltext subtotal">
                          Discount:
                        </span>
                        <span className="o2e-cart-totaltext discountprice ml-auto">
                          {deliveryData ? "£" + deliveryData.discount : "£0.0"}
                        </span>
                      </div>
                      <div className="o2e-cartrow d-flex mb-2">
                        <span className="o2e-cart-totaltext subtotal">
                          Delivery Fee:
                        </span>
                        <span className="o2e-cart-totaltext discountprice ml-auto">
                          {deliveryData
                            ? "£" + deliveryData.delivery_charges
                            : "£0.0"}
                        </span>
                      </div>
                    </div>
                    <div className="o2e-cart-mastertotal p-2">
                      <div className="o2e-cartrow d-flex">
                        <span className="o2e-cart-totaltext subtotal">
                          TOTAL
                        </span>
                        <span className="o2e-cart-totaltext discountprice ml-auto">
                          £{finalItemPrice}
                        </span>
                      </div>
                    </div>

                    <div className="o2e-place-orderform margin-form">
                      <form className="placeorder-rorm">
                        <div className="form-group mb-2">
                          <input
                            type="email"
                            className="form-control rounded-0 border-0 bg-white"
                            placeholder={checked ? "Email *" : "Email (Optional)"}
                            value={values.Email}
                            onChange={handleInputChange}
                            name="Email"
                          />
                          {emailError ? <p className="error-input">Please complete this</p> : ''}
                        </div>
                        <div className="form-group mb-2">
                          <input
                            type="text"
                            className="form-control rounded-0 border-0 bg-white"
                            id="o2eName"
                            placeholder="Name *"
                            value={values.Name}
                            onChange={handleInputChange}
                            name="Name"
                          />
                          {nameError ? <p className="error-input">Please complete this</p> : ''}
                        </div>
                        <div className="form-group mb-2">
                          <input
                            type="tel"
                            className="form-control rounded-0 border-0 bg-white"
                            id="o2eMobileNumber"
                            placeholder="Mobile *"
                            value={values.Mobile}
                            onChange={handleInputChange}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            name="Mobile"
                            maxLength="11"
                          />
                          {mobileError ?<p className="error-input">Please complete this</p> : ''}
                        </div>
                        <div className="form-group mb-2">
                          <input
                            type="text"
                            className="form-control rounded-0 border-0 bg-white"
                            id="o2eAddresspasscode"
                            placeholder="Address *"
                            value={values.Address}
                            onChange={handleInputChange}
                            name="Address"
                          />
                          {addressError ? <p className="error-input">Please complete this</p> : ''}
                        </div>
                        <div className="form-group mb-2">
                          <textarea
                            className="form-control rounded-0 border-0 bg-white"
                            id="o2eComment"
                            rows="5"
                            placeholder="Comments"
                            value={values.Comments}
                            onChange={handleInputChange}
                            name="Comments"
                          ></textarea>
                        </div>

{localStorage.getItem("user_id") ?'' :
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              defaultChecked={checked}
                              onChange={() => setChecked(!checked)}
                            />{" "}
                            Create an account?
                          </label>
                        </div>}

                        {checked ? (
                          <>
                            <div className="form-group mb-2">
                              <input
                                type="password"
                                className="form-control rounded-0 border-0 bg-white"
                                id="o2eAddresspasscode"
                                placeholder="Password *"
                                value={values.Password}
                                onChange={handleInputChange}
                                name="Password"
                              />
                              {passwordError ? <p className="error-input">Please complete this</p> : ''}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="form-group mb-2">
                          <button
                            disabled={yes ? true : false}
                            type="button"
                            className="btn btn-block border-0 btn-warning text-center"
                            onClick={placeOrder}
                          >
                            Place Order
                          </button>
                        </div>
                      </form>
                      <div className="o2e-termscondition small p-2 my-2 d-flex justify-space-around flex-custom fo-313">
                        By placing an order you agree to our{" "}
                        <Link to="/terms" target="_blank" rel="noopener noreferrer" className="link fo-13">
                          Terms & Conditions
                        </Link>

                        
                      </div>
                    </div>
                  </div>
                </div>
              }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
