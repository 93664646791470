import React from "react";
import Header from '../layouts/Header'

const NotFound = () => {
  return (
    <>
    <Header />
    <div className="not-found">
      <h1 className="display-1">Page Not Found</h1>
    </div>
    </>
  );
};

export default NotFound;
