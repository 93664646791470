import React ,{useState, useEffect} from "react";
import { Link , useHistory} from "react-router-dom";
import Toggler from '../../images/square.png'
const Header = (props) => {

const [toggler , setToggler] = useState(false)

    const [width, setWidth]   = useState(window.innerWidth);
const [height, setHeight] = useState(window.innerHeight);
const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
}

useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
}, []);

    let history = useHistory();
    const [count, setCount] = useState(0)

const removeLocalData = () => {
    
    localStorage.clear();
    setCount(1)
    history.push("/login")
}

const openToggler = () => {
    setToggler(!toggler)
}
  return (
    <>


<div className={props.search ? "header-main-container-2" : "header-main-container" } style={props.cartHeader && width < 768 ? {display:'none'} : {display:'block'}}>
<div style={{display:"none"}}>{count}</div>
<div className={props.takeaway ? "container-fluid" : props.search ?  "container search-container p-container" : "container"}>
<div className="header-cont">
<div className="tablet-show visibility-none">
        <h1>O</h1>
    </div>
<div className="logo-container">
<div>
                <a
                    className="logo-link"
                    href={`/`}
                  ><img className="img-fluid tablet-hide" src="/images/dark-logo.png" alt="Order 2 Eat" />
                  <img className="img-fluid tablet-show" alt="Order 2 Eat" src="/images/logo.png" />
</a>
                </div>

                <ul className={toggler ? "navbar-mobile-nav tablet-show" : "navbar-mobile-nav tablet-show hide-nav"}>
                        <li className="nav-item help"><a className="nav-link" href={`/`}>Home</a></li>
                        <li className="nav-item help"><a className="nav-link" href="# ">Help</a></li>
                        <li className="nav-item help"><a className="nav-link" href="# ">Contact Us</a></li>


                            <li className="nav-item login"><Link className="nav-link" to={localStorage.getItem("user_id") ? '/dashboard' : '/login'}>{localStorage.getItem("user_id") ? "Hello " + localStorage.getItem("username") : "Login"}</Link></li>
                            <li className={localStorage.getItem("user_id") ? "none" : 'nav-item register' }><Link className="nav-link" to={localStorage.getItem("user_id") ? '/dashboard' : '/register'}>{localStorage.getItem("user_id") ? null : "Register"}</Link></li>


                            {localStorage.getItem("user_id") ? 
                            <li className="nav-item logout" onClick={() => removeLocalData()}><a className="nav-link" href="#">Logout</a></li> : '' }
                            
                        </ul>
</div>

<div className="menu-container">
    
<ul className="navbar-nav o2e-dark-nav navbar-ul">
                        <li className="nav-item help"><a className="nav-link" href={`/`}>Home</a></li>
                        <li className="nav-item help"><a className="nav-link" href="# ">Help</a></li>
                        <li className="nav-item help"><a className="nav-link" href="# ">Contact Us</a></li>


                            <li className="nav-item login"><Link className="nav-link" to={localStorage.getItem("user_id") ? '/dashboard' : '/login'}>{localStorage.getItem("user_id") ? "Hello " + localStorage.getItem("username") : "Login"}</Link></li>
                            <li className={localStorage.getItem("user_id") ? "none" : 'nav-item register' }><Link className="nav-link" to={localStorage.getItem("user_id") ? '/dashboard' : '/register'}>{localStorage.getItem("user_id") ? null : "Register"}</Link></li>


                            {localStorage.getItem("user_id") ? 
                            <li className="nav-item logout" onClick={() => removeLocalData()}><a className="nav-link" href="#">Logout</a></li> : '' }
                            
                        </ul>
                        <div class="cart-wrapper p-1 p-md-2 align-items-center ml-auto custom-basket" >
                            <div class="cart-count text-center w-100" >{localStorage.getItem('total_qty') ? localStorage.getItem('total_qty') : '0'}</div>
                        </div>
            <div className="tablet-show" onClick={() => openToggler()}>
                        <img class="toggle-img" src={Toggler} />
                        </div>
</div>
</div>
</div>
</div>
</>
  );
};

export default Header;
