  import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SnackBar from "my-react-snackbar";
import Backtocart from '../../images/cart.png'
import Profile from '../../images/profile-user.png'
import Reviews from '../../images/review.png'
import shoppingbag from '../../images/shopping-bag.png'
import lock from '../../images/lock.png'
import SecureLS from "secure-ls";
import Header from '../layouts/Header'
var ls = new SecureLS({encodingType: 'aes'});

const authAxios = global.authAxios;

const initialValues = {
  Email: "",
  Password: "",
  ConfirmPassword: "",
  Name: "",
  Phone: "",
  Address: "",
};

export default function Dashboard() {
  const  id  = ls.get("user_id")
  const [active, setActive] = useState("1");
  const [review, setReviewData] = useState();
  const [order, setOrderData] = useState();
  const [values, setValues] = useState(initialValues);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [type, setType] = useState("error");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setInitialData();
  }, []);

  const setInitialData = async () => {
    const customerData = await authAxios.get(`/loadCustomer/${id}`);

    setValues({
      Email: customerData.data.customer.email,
      Name: customerData.data.customer.full_name,
      Address: customerData.data.customer.address,
      Phone: customerData.data.customer.phone,
    });
  };

  const handleInputChange = (e) => {
    
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const setActivePanel = async (e) => {
    setActive(e.target.id);

    if (e.target.id === "2") {
      const res = await authAxios.get(`/userReviews/${id}`);
      setReviewData(res.data);
    }

    if (e.target.id === "3") {
      const res = await authAxios.get(`/userOrders/${id}`);
      setOrderData(res.data);
    }
  };

  const editProfile = async () => {
    setLoader(true);
    var URL = "/editProfile";
    var profileData = {
      user_id: id,
      full_name: values.Name,
      phone: values.Phone,
      address: values.Address,
    };
    await authAxios.post(URL, profileData).then((response) => {
      if (response.data.message === "success") {
        setOpen(true);
        setMessage("Profile Edited Successfully");
        setType("success");
        setLoader(false);
      }
    });
  };

  const changePassword = async() => {
    setOpen(false)
    var URL = "/changePass";
    var passwordData = {
      user_id: id,
      password: values.Password,
      password_confirmation : values.ConfirmPassword
    };
    await authAxios.post(URL, passwordData).then((response) => {
      if (response.data.message === "success") {
        setOpen(true);
        setMessage("Password Changed Successfully");
        setType("success");
      }
      else{
        setOpen(true);
        setMessage(response.data.data.error.password[0]);
        setType("error");
      }
    });
  }

  return (
    <>
    <Header />
      <SnackBar
        open={open}
        message={message}
        position="top-right"
        type={type}
        yesLabel="Ok"
        onYes={() => setOpen(false)}
      />
      <main id="nce-main-content">
        <div className="container" id="main_container">
          <div className="row">
            <div className="col-md-3">
              <div className="row">
                <div className="col-sm-6 col-lg-12">
                  <div className="card">
                    <div className="card-block p-0 clearfix">
                      
                      <img className="bg-danger p-4 px-5 ote-5 font-2xl mr-3 float-left" src={Backtocart} alt="danger"/>

                      <Link to="/takeaways" className="cart-link">
                      <div className="h5 mb-0 pt-3 text-center href">
                        Back to cart
                      </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-12">
                  <div className="card">
                    <div className="card-block p-0 clearfix">
                      
                      <img className="bg-primary p-4 px-5 ote-5 font-2xl mr-3 float-left" src={Profile} alt="danger"/>
                      <div
                        className="h5 mb-0 pt-3 text-center href"
                        id={1}
                        onClick={(e) => setActivePanel(e)}
                      >
                        Edit Profile
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-12">
                  <div className="card">
                    <div className="card-block p-0 clearfix">
                      
                      <img className="bg-success p-4 px-5 ote-5 font-2xl mr-3 float-left" src={Reviews} alt="success"/>
                      <div
                        className="h5 mb-0 pt-3 text-center href"
                        id={2}
                        onClick={(e) => setActivePanel(e)}
                      >
                        My Reviews
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-12">
                  <div className="card">
                    <div className="card-block p-0 clearfix">
                      
                      <img className="bg-info p-4 px-5 ote-5 font-2xl mr-3 float-left" src={shoppingbag} alt="shopping"/>

                      <div
                        className="h5 mb-0 pt-3 text-center href"
                        id={3}
                        onClick={(e) => setActivePanel(e)}
                      >
                        My Orders
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-12">
                  <div className="card">
                    <div className="card-block p-0 clearfix">
                      
                      <img className="bg-primary p-4 px-5 ote-5 font-2xl mr-3 float-left" src={lock} alt="lock"/>
                      <div
                        className="h5 mb-0 pt-3 text-center href"
                        id={4}
                        onClick={(e) => setActivePanel(e)}
                      >
                        Change Password
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {active === "1" ? (
              <div className="col-md-6">
                {" "}
                <div className="panel panel-default">
                  <div className="panel-heading nce-cart-head">Edit Profile</div>
                  <div className="panel-body">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        defaultValue={values.Email}
                        name="Email"
                        placeholder="Email"
                        className="input-xlarge form-control"
                        required="required"
                        readOnly={true}
                      />
                    </div>

                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        value={values.Name}
                        onChange={(e) => handleInputChange(e)}
                        name="Name"
                        placeholder="Name"
                        required="required"
                        className="input-xlarge form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Phone</label>
                      <input
                        type="tel"
                        value={values.Phone}
                        onChange={(e) => handleInputChange(e)}
                        name="Phone"
                        placeholder="Phone"
                        className="input-xlarge form-control"
                        required="required"
                        maxLength="11"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Address + Postcode</label>
                      <textarea
                        name="Address"
                        value={values.Address}
                        onChange={(e) => handleInputChange(e)}
                        cols="40"
                        rows="2"
                        placeholder="Address"
                        className="input-xlarge form-control"
                        required="required"
                      ></textarea>
                    </div>
                    {loader ? (
                      <div className="col-md-12" id="loader_img">
                        <img src="https://www.justorder2eat.co.uk/public/front/assets/images/ajax-loader.gif" alt="loading"/>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form-actions">
                      <button
                        id="btn_reg"
                        type="submit"
                        className="btn btn-primary"
                        name="btn"
                        onClick={() => editProfile()}
                      >
                        Edit Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : active === "2" ? (
              <div className="col-md-9">
                <div className="panel panel-default">
                  <div className="panel-heading nce-cart-head">Manage Reviews</div>
                  <div className="panel-body">
                    <div className="box-content nopadding dataTables_wrapper">
                      <div className="table-responsive">
                        <style type="text/css"></style>
                        <table className="table table-hover table-nomargin table-bordered jrt jrt-instance-1">
                          <thead>
                            <tr>
                              <th>Comment</th>
                              <th>Rating</th>
                              <th>Date</th>
                              <th>Takeway</th>
                              <th>Status</th>
                            </tr>

                            {review && review.data && review.data.length > 0
                              ? review.data.map((x) => {
                                  return (
                                    <tr>
                                      <td>{x.desc}</td>
                                      <td>{x.score}</td>
                                      <td>{x.date}</td>
                                      <td>{x.takeaway_name}</td>
                                      <td>{x.status}</td>
                                    </tr>
                                  );
                                })
                              : ""}
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : active === "3" ? (
              <div className="col-md-9">
                <div className="panel panel-default">
                  <div className="panel-heading nce-cart-head">My Orders</div>
                  <div className="panel-body">
                    <div className="box-content nopadding dataTables_wrapper">
                      <table className="table table-hover table-nomargin table-bordered jrt jrt-instance-1">
                        <thead>
                          <tr>
                            <th>Order Date</th>
                            <th>Takeway</th>
                            <th>Total </th>
                            <th>Status </th>
                          </tr>

                          {order && order.data && order.data.length > 0
                            ? order.data.map((x) => {
                                return (
                                  <tr>
                                    <td>{x.order_date}</td>
                                    <td>{x.takeaway_name}</td>
                                    <td>
                                      {x.order_currency}
                                      {x.order_total}
                                    </td>
                                    <td>{x.payment_status}</td>
                                  </tr>
                                );
                              })
                            : ""}
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : active === "4" ? (
              <>
                <div className="col-md-6">
                  {" "}
                  <div className="panel panel-default">
                    <div className="panel-heading nce-cart-head">Change Password</div>
                    <div className="panel-body">
                      <div className="form-group">
                        <div className="form-group">
                          <label htmlFor="pass" className="control-label">
                            Password
                          </label>
                          <input
                            type="password"
                            name="Password"
                            value={values.Password}
                            required="required"
                            placeholder="Password"
                            className="input-xlarge form-control"
                            onChange={(e) => handleInputChange(e)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="pass" className="control-label">
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            name="ConfirmPassword"
                            value={values.ConfirmPassword}
                            required="required"
                            placeholder="Password"
                            className="input-xlarge form-control"
                            onChange={(e) => handleInputChange(e)}
                          />
                        </div>
                        <div className="form-actions">
                      <button
                        id="btn_reg"
                        type="submit"
                        className="btn btn-primary"
                        name="btn"
                        onClick={() => changePassword()}
                      >
                        Change Password
                      </button>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </main>
    </>
  );
}
