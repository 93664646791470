import React, { useState } from "react";
import SnackBar from "my-react-snackbar";
import Header from '../layouts/Header'

const initialValues = {
  Email: "",
  Password: "",
  FullName:"",
  Phone:"",
  Address:"",
};

const authAxios = global.authAxios;

export default function Register() {
  
  const [values, setValues] = useState(initialValues);
  const [loader, setLoader] = useState(false)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState(false)
  const [type, setType] = useState("error")

  const handleInputChange = (e) => {
    //const name = e.target.name
    //const value = e.target.value
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };



  const Register = async (e) => {
    e.preventDefault()
    setOpen(false)
    setLoader(true)
    if(values.Email === '' || values.Password === '' || values.FullName === '' || values.Phone === '' || values.Address === '' ){
      setOpen(true)
      setMessage("All Fields are required") 
      setLoader(false)
    }
      else{
    var URL = "/register";
    var registerData = {email: values.Email , pass: values.Password, full_name:values.FullName, phone:values.Phone, address: values.Address}
    await authAxios.post(URL, registerData).then((response) => {
      if(response.data.message === "success"){
        setLoader(false)
        setOpen(true)
        setMessage("Registered successfully please login to continue")
        setType("success")
      }
      else{
        setOpen(true)
        setMessage(response.data.message)
        setType("error")
        setLoader(false)
      }
    });
  }
  };

  return (
    <>
    <Header />
    <div>
        <SnackBar
          open={open}
          message={message}
          position="top-right"
          type={type}
          yesLabel="Ok"
          onYes={() => {}}
        />
      </div>
      <main id="nce-main-content">
        <div className="container" id="main_container">
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-9">
                <div id="error"></div>

                <div className="col-md-8">
                  <div className="panel panel-default">
                    <div className="panel-heading nce-cart-head">Register</div>
                    <div className="panel-body">
                      <form
                        id="frm_main"
                        name="frm_main"
                        method="post"
                        action="https://www.justorder2eat.co.uk/user/login"
                        novalidate="novalidate"
                      >
                        <div className="form-group">
                          {" "}
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email *"
                            value={values.Email}
                            onChange={handleInputChange}
                            name="Email"
                          />
                        </div>
                        <div className="form-group">
                          {" "}
                          <input
                                type="password"
                                className="form-control"
                                id="o2eAddresspasscode"
                                placeholder="Password *"
                                value={values.Password}
                                onChange={handleInputChange}
                                name="Password"
                        />
                        </div>

                        <div className="form-group">
                          {" "}
                          <input
                                type="text"
                                className="form-control"
                                id="o2eAddresspasscode"
                                placeholder="Full Name *"
                                value={values.FullName}
                                onChange={handleInputChange}
                                name="FullName"
                        />
                        </div>

                        <div className="form-group">
                          {" "}
                          <input
                                type="tel"
                                className="form-control"
                                id="o2eAddresspasscode"
                                placeholder="Phone *"
                                value={values.Phone}
                                onChange={handleInputChange}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                name="Phone"
                                maxLength="11"
                        />
                        </div>

                        <div className="form-group">
                          {" "}
                          <input
                                type="text"
                                className="form-control"
                                id="o2eAddresspasscode"
                                placeholder="Address *"
                                value={values.Address}
                                onChange={handleInputChange}
                                name="Address"
                        />
                        </div>
                        <div className="form-group ">
                          <input type="hidden" name="redirect_to" value="" />
                         {loader? <div className="col-md-12" id="loader_img">
                            <img src="https://www.justorder2eat.co.uk/public/front/assets/images/ajax-loader.gif" alt="loader"/>
                          </div> : ''}
                          <div className="col-md-12 btn-style">
                            <input
                              type="submit"
                              id="btn-login"
                              value="Sign me up"
                              className="btn btn-danger btn_ote"
                              name="btn"
                              onClick={(e) => Register(e)}
                            />
                            
                          </div>
                        </div>
                        
                      </form>{" "}
                      <div className="forget"> &nbsp; </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
