import React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
 
const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer-main">
            <div className="container footer-menu-desktop d-none d-md-block">
                <div className="row">
                    <div className="col-lg col-md footer-widget">
                        <h3 className="widget-title">Browse by cuisine:</h3>
                        <div className="widget-content d-md-block d-lg-flex collapse">
                            <div className="d-flex flex-wrap align-items-start">
                                <ul className="nav widget-nav col-lg-4 flex-column">
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Browse All Cuisines</a></li>
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Halal Delivery</a></li>
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Japanese Delivery</a></li>
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Thai Delivery</a></li>
                                </ul>
                                <ul className="nav widget-nav col-lg-4 flex-column">
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Asian Delivery</a></li>
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Indian Delivery</a></li>
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Middle Eastern Delivery</a></li>
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">View All</a></li>
                                </ul>
                                <ul className="nav widget-nav col-lg-4 flex-column">
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Chinese Delivery</a></li>
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Pizza Delivery</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg col-md footer-widget">
                        <h3 className="widget-title">Browse by city:</h3>
                        <div className="widget-content d-md-block d-lg-flex collapse">
                            <div className="d-flex flex-wrap align-items-start">
                                <ul className="nav widget-nav col-lg-4">
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Browse All Cities</a></li>
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Leeds Food Delivery</a></li>
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">London Food Delivery</a></li>
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Birmingham Food Delivery</a></li>
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Leicester Food Delivery</a></li>
                                </ul>
                                <ul className="nav widget-nav col-lg-4">
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Leicester Food Delivery</a></li>
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Manchester Food Delivery</a></li>
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Brighton Food Delivery</a></li>
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Glasgow Food Delivery</a></li>
                                </ul>
                                <ul className="nav widget-nav col-lg-4">
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Liverpool Food Delivery</a></li>
                                    <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Newcastle Food Delivery</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


{/* 

            <div className="container footer-menu-mobile d-md-none">
                <div className="accordion" id="accordionExample">
                    <div className="card p-0 border-0 mb-3">
                        <div className="card-header border-0 px-0 py-1 bg-transparent" id="headingOne">
                            <h3 className="mb-0">
                                <button className="btn d-flex text-left justify-content-between align-items-center w-100 p-0" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    Browse by cuisine:<i className="fa fa-chevron-down d-md-none ml-auto"></i>
                                </button>
                            </h3>
                        </div>
                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div className="card-body p-0">
                                <div className="d-flex flex-wrap align-items-start">
                                    <ul className="nav widget-nav flex-column col-md-4">
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Browse All Cities</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Leeds Food Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">London Food Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Birmingham Food Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Leicester Food. Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Leicester Food. Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Manchester Food Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Brighton Food Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Glasgow Food Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Liverpool Food Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Newcastle Food Delivery</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card p-0 border-0">
                        <div className="card-header border-0 px-0 py-1 bg-transparent" id="headingTwo">
                            <h3 className="mb-0">
                                <button className="btn d-flex text-left justify-content-between align-items-center w-100 p-0 collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Browse by city:<i className="fa fa-chevron-down d-md-none ml-auto"></i>
                                </button>
                            </h3>
                        </div>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div className="card-body p-0">
                                <div className="d-flex flex-wrap align-items-start">
                                    <ul className="nav widget-nav flex-column col-md-4">
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Browse All Cuisines</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Halal Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Japanese Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Thai Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Asian Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Indian Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Middle Eastern Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Chinese Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">Pizza Delivery</a></li>
                                        <li className="nav-item"><a className="nav-link px-0 py-1" href="# ">View All</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div> */}
        </div>

        <div className="o2e-footer-dark d-none d-md-block">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-6">
                        <div className="o2e-menu-white mb-3">
                            <ul className="nav text-center text-lg-left">
                                <li className="nav-item"><a className="nav-link" href="#">About Us</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Log in</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Sign up</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">My Account</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Need Help?</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Download Our New Web Mobile App</a></li>
                            </ul>
                        </div>
                        <div className="o2e-menu-white mb-4 mb-lg-0">
                            <ul className="nav text-center text-lg-left">
                                <li className="nav-item"><a className="nav-link" href="#">Privacy Policy</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Terms and conditions</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Contact Us</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Takeaway signup/refer</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 o2e-footer-right">
                        <div className="o2e-footer-address text-center text-lg-right">
                            <h3 className="widget-title">Head Office</h3>
                            <p>Order 2 Eat Ltd - International House, 24 Holborn Viaduct, London EC1A 2BN</p>
                            <ul className="nav o2e-right-menu justify-content-end">
                                <li className="nav-item"><a className="nav-link" href="#">Tel No:-  0333 344 0808</a></li>
                                <li className="nav-item"><a className="nav-link" href="mailto:hello@order2eat.co.uk" target="_top">Email:- hello@order2eat.co.uk</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Website:-www.order2eat.co.uk</a></li>
                                <li className="nav-item" style={{color:"#ffffff", fontSize:"12px;"}}>Registered company number 11002890</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <div className="footer-bottom py-4 pt-0">
            <div className="container">
                <div className="row d-flex flex-w flex-column">
                    
                    <div className="col-md-6 col-12 mb-md-0 mb-2 d-md-flex justify-content-end text-md-right text-center m-width justify-around justify-center">
                    <div class="col-md-6 col-12 mb-md-0 mb-2 d-none d-md-block pl-0">
                        <ul class="nav o2e-social-menu">
                            <li class="nav-item"><a class="nav-link" href="#"><i class="fab fa-facebook-f"></i></a></li>
                            <li class="nav-item"><a class="nav-link" href="#"><i class="fab fa-twitter"></i></a></li>
                            <li class="nav-item"><a class="nav-link" href="#"><i class="fab fa-google-plus-g"></i></a></li>
                            <li class="nav-item"><a class="nav-link" href="#"><i class="fas fa-rss"></i></a></li>
                            <li class="nav-item"><a class="nav-link" href="#"><i class="fab fa-instagram"></i></a></li>
                            <li class="nav-item"><a class="nav-link" href="#"><i class="fab fa-pinterest-p"></i></a></li>
                        </ul>
                    </div>
                        <img src="/images/payment.jpg" alt="payment" />
                    </div>
                    <div className="col-12">
                        <div className="footer-text text-center">
                            <p>Copyright © 2020 - Order 2 Eat. All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  );
};

export default Footer;
