import axios from 'axios'

global.apiUrl ='https://meal2eat.co.uk/api';
global.frontEndUrl = "https://eattakeaway.co.uk/"

global.authAxios = axios.create(
  {
    baseURL:global.apiUrl,
  }
)

global.authAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('ote_toke');
    if (token) {
      config.headers.authorization = 'Bearer ' + token;
    }
    return config;
  },
  (error) => Promise.reject(error),
);