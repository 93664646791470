
import Header from '../layouts/Header';

export const Refer = () => {
    return(
        <>
        <Header  />
        <section class="section o2e-refer-local">
        <div class="container">
            <div class="row">
                <div class="col-12 mx-auto text-center">
                    <h1>Refer your local takeaways to us</h1>
                </div>
                <div class="col-lg-10 mx-auto text-center">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen 
book. It has survived not only five centuries,</p>
                </div>
                <div class="col-10 mx-auto mt-3 mb-3 mb-md-5">
                    <div class="o2e-shadow"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="card o2e-custpmer-care mb-4 mb-md-5">
                        <div class="card-header bg-transparent d-flex border-0 align-items-center">
                            <img class="img-fluid mr-2 mr-md-3" alt="Customer Care" src="images/customer-care.png" />
                            <h2>Customer Care</h2>
                        </div>
                        <div class="card-body py-0">
                            <p>Your digital assistant Sara is now online. If you have a question with your order or anything else, just click on the fb messenger icon and let's chat. Sara, can answer most your question and put your mind in rest.</p>
                        </div>
                        <div class="card-footer border-0 bg-transparent d-flex">
                            <button class="btn btn-warning btn-block text-center mx-auto">Let's Chat</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card o2e-custpmer-care mb-4 mb-md-5">
                        <div class="card-header bg-transparent d-flex border-0 align-items-center">
                            <img class="img-fluid mr-2 mr-md-3" alt="Customer Care" src="images/o2e-options.png" />
                            <h2>Options</h2>
                        </div>
                        <div class="card-body py-0">
                            <p>Your digital assistant Sara is now online. If you have a question with your order or anything else, just click on the fb messenger icon and let's chat. Sara, can answer most your question and put your mind in rest.</p>
                        </div>
                        <div class="card-footer border-0 bg-transparent d-sm-flex">
                            <button class="btn btn-warning btn-block text-center mr-sm-1 m-0">Refer a Takway</button>
                            <button class="btn btn-danger btn-block text-center ml-sm-1 m-sm-0 mt-2">Search Again</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-7 mx-auto">
                    <div class="card o2e-text-messanger border-0 mb-0 mx-auto">
                        <div class="card-body text-center p-4">
                            <p>Lorem Ipsum is simply dummy text of the printing and </p>
                            <button class="btn btn-primary btn-block text-center mb-1 mx-auto d-flex align-items-center justify-content-center">
                                <img alt="" class="mr-2" src="images/messanger.png" />
                                Go to Messeanger
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
    )
}